<template>
    <div class="config-logo">
        <img :style="logoStyle" :src="logoUrl" alt="config.title">
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'LogoRow',
    data() {
        return {
            logoStyle: { 'width': '60%', 'max-width': '500px' }
        }
    },
    computed: {
        ...mapState([
            'config',
            'logoUrl'
        ])
    },
    methods: {
        computeLogoStyle(aspectRatio) {
            if (aspectRatio < 0.5) {
                this.logoStyle = { 'width': '50%', 'max-width': '150px' }
            } else if (aspectRatio < 0.75) {
                this.logoStyle = { 'width': '50%', 'max-width': '175px' }
            } else if (aspectRatio < 1) {
                this.logoStyle = { 'width': '50%', 'max-width': '200px' }
            } else if (aspectRatio < 1.25) {
                this.logoStyle = { 'width': '50%', 'max-width': '250px' }
            } else if (aspectRatio < 1.5) {
                this.logoStyle = { 'width': '50%', 'max-width': '275px' }
            } else if (aspectRatio < 1.75) {
                this.logoStyle = { 'width': '50%', 'max-width': '300px' }
            } else if (aspectRatio < 2) {
                this.logoStyle = { 'width': '55%', 'max-width': '350px' }
            } else if (aspectRatio < 3) {
                this.logoStyle = { 'width': '60%', 'max-width': '425px' }
            } else if (aspectRatio < 4) {
                this.logoStyle = { 'width': '65%', 'max-width': '500px' }
            } else if (aspectRatio < 5) {
                this.logoStyle = { 'width': '70%', 'max-width': '600px' }
            } else if (aspectRatio < 6) {
                this.logoStyle = { 'width': '75%', 'max-width': '700px' }
            } else {
                this.logoStyle = { 'width': '80%', 'max-width': '800px' }
            }
        }
    },
    mounted() {
        let img = new Image()
        img.onload = function() {
            this.computeLogoStyle(img.naturalWidth / img.naturalHeight)
        }.bind(this)
        img.src = this.logoUrl
    }
}
</script>

<style>
.config-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding:12px 12px 24px 12px;
}
</style>
import Vue from 'vue'
import Vuex from 'vuex'
import VueJsonp from 'vue-jsonp'

const convert = require('xml-js')
const tinycolor = require('tinycolor2')

Vue.use(Vuex)
Vue.use(VueJsonp)

const defaultState = () => {
    return {
        broadcast: {},
        broadcastEmpty: false,
        broadcastHistory: {},
        broadcastReady: false,
        colorPrimary: '#000000',
        colorSecondary: '#ffffff',
        config: {layout:{}},
        configLink: '',
        configLoaded: false,
        configParent: '//apps.blueframetech.com/api/v1/',
        cookies: {},
        dark: true,
        errors: [],
        filters: {showSitesFilter: false, showSectionsFilter: false, showDateFilter: false, showTitleFilter: false},
        firstLoad: true,
        forceSelect: false,
        fullSearch: false,
        hasApps: false,
        hasBroadcasts: false,
        hasLinks: false,
        hasLogo: false,
        hasNews: false,
        hasSchedules: false,
        hasSites: false,
        hasSponsors: false,
        hasStats: false,
        hasWidget: false,
        layout: [],
        layoutLoaded: false,
        labels: {
            broadcastLabel: 'Broadcast',
            broadcastLabelPlural: 'Broadcasts',
            siteLabel: 'Site',
            siteLabelPlural: 'Sites',
            sectionLabel: 'Section',
            sectionLabelPlural: 'Sections',
            appLabel: 'App',
            appLabelPlural: 'Apps'
        },
        logoUrl: '',
        links: [],
        mainLoaded: false,
        mode: 'default',
        offset: 0,
        omnisearch: '',
        portalNavigation: false,
        position: {x:0, y:0},
        props: {},
        queue: 0,
        search: false,
        searchIndex: 0,
        searchOmni: false,
        searchQueries: [],
        searchReady: false,
        sectionsString: '',
        sectionArray: [],
        selectedSite: null,
        setUrl: false,
        sharedSites: null,
        showSearch: false,
        siteArray: [],
        siteSlugs: {},
        sites: {},
        sitesString: '',
        sponsors: [],
        viewApps: true,
        viewBroadcasts: true,
        viewPlayer: true,
        viewNews: true,
        viewSchedules: true,
        viewSites: true,
        whiteLabeled: false,
        widget: {},
        width: 0
    }
}

export default new Vuex.Store({
    state: defaultState(),
    getters: {
        mobile: state => {
            // 576 - 15 for scroll bar
            if (state.width < 561) {
                return true
            } else {
                return false
            }
        },
    },
    mutations: { // setters
        ADD_LAYOUT (state, layout_object) {
            Vue.set(state.layout, state.layout.length, layout_object)
            switch (layout_object.type) {
                case 'broadcast':
                    state.hasBroadcasts = true
                    break
                case 'site':
                    state.hasSites = true
                    break
                case 'embeddedApp':
                    state.hasApps = true
                    break
                case 'videoSourceSchedule':
                    state.hasSchedules = true
                    break
                case 'news':
                    state.hasNews = true
                    break
            }
        },
        ADD_SEARCHQUERY (state, query_object) {
            Vue.set(state.searchQueries, state.searchQueries.length, query_object)
        },
        DECREMENT_QUEUE (state) {
            --state.queue
        },
        INCREMENT_QUEUE (state) {
            ++state.queue
        },
        LOAD_STATE (state, id) {
            let saved_state = JSON.parse(window.sessionStorage.getItem(id))
            for (const [key, value] of Object.entries(saved_state)) {
                Vue.set(state, key, value)
            }
        },
        RESET_DEFAULT (state) {
            Object.assign(state, defaultState())
        },
        RESET_STATE (state) {
            let new_state = {}
            Object.assign(new_state, defaultState())
            new_state.mode = state.mode
            new_state.dark = state.dark
            new_state.width = state.width
            new_state.configParent = state.configParent
            new_state.setUrl = state.setUrl
            new_state.props = state.props
            new_state.props.portalsite = null
            new_state.props.portalbroadcast = null
            Object.assign(state, new_state)
        },
        SAVE_STATE (state, config_object) {
            let state_to_save = {}
            if (config_object.all) {
                Object.assign(state_to_save, state)
            } else {
                state_to_save.broadcast = state.broadcast
                state_to_save.broadcastEmpty = state.broadcastEmpty
                state_to_save.broadcastReady = state.broadcastReady
                state_to_save.selectedSite = state.selectedSite
                state_to_save.configLink = state.configLink
            }
            window.sessionStorage.setItem(config_object.id, JSON.stringify(state_to_save))
        },
        SET_BROADCAST (state, broadcast_object) {
            Vue.set(state, 'broadcast', broadcast_object)
            if (typeof(Object.keys(broadcast_object)) !== 'undefined' && Object.keys(broadcast_object).length > 0) {
                state.hasBroadcasts = true
                state.broadcastHistory[broadcast_object.id] = broadcast_object
                if (typeof(state.broadcast.live_stats_embed) !== 'undefined') {
                    state.hasStats = true
                } else {
                    state.hasStats = false
                }
                state.broadcastEmpty = false
                state.broadcastReady = true
            } else {
                state.hasStats = false
                state.broadcastReady = false
                state.broadcastEmpty = true
            }
        },
        SET_BROADCASTEMPTY (state, empty_bool) {
            state.broadcastEmpty = empty_bool
        },
        SET_BROADCASTREADY (state, ready_bool) {
            state.broadcastReady = ready_bool
        },
        SET_COLORPRIMARY (state, color_string) {
            state.colorPrimary = color_string
        },
        SET_COLORSECONDARY (state, color_string) {
            state.colorSecondary = color_string
        },
        SET_DARK (state, dark_bool) {
            state.dark = dark_bool
        },
        SET_CONFIG (state, config_object) {
            Vue.set(state, 'config', config_object)
            if (typeof(config_object.labels) !== 'undefined') {
                Vue.set(state, 'labels', config_object.labels)
            }
        },
        SET_CONFIGLINK (state, dir_string) {
            state.configLink = dir_string
        },
        SET_CONFIGLOADED (state, loaded_bool) {
            state.configLoaded = loaded_bool
        },
        SET_CONFIGPARENT (state, bucket_string) {
            state.configParent = bucket_string
        },
        SET_COOKIES (state, cookies_object) {
            Vue.set(state, 'cookies', cookies_object)
        },
        SET_ERROR (state, error_string) {
            state.errors.push(error_string)
        },
        SET_FILTERS (state, filter_object) {
            state.filters.showSitesFilter = filter_object.showSitesFilter
            state.filters.showSectionsFilter = filter_object.showSectionsFilter
            state.filters.showDateFilter = filter_object.showDateFilter
            state.filters.showTitleFilter = filter_object.showTitleFilter
            if (state.searchOmni || state.filters.showSitesFilter || state.filters.showSectionsFilter || state.filters.showDateFilter || state.filters.showTitleFilter) {
                state.showSearch = true
            }
        },
        SET_FIRSTLOAD (state, load_bool) {
            state.firstLoad = load_bool
        },
        SET_FORCESELECT (state, force_bool) {
            state.forceSelect = force_bool
        },
        SET_FULLSEARCH (state, full_bool) {
            state.fullSearch = full_bool
        },
        SET_LAYOUTLOADED (state, loaded_bool) {
            state.layoutLoaded = loaded_bool
        },
        SET_LINKS (state, link_array) {
            state.links = link_array
            state.hasLinks = true
        },
        SET_LOGOURL (state, url_string) {
            state.logoUrl = url_string
            state.hasLogo = true
        },
        SET_MAINLOADED (state, loaded_bool) {
            state.mainLoaded = loaded_bool
        },
        SET_MODE (state, mode_string) {
            state.mode = mode_string
        },
        SET_OFFSET (state, offset_number) {
            state.offset = offset_number
        },
        SET_OMNISEARCH (state, search_string) {
            state.omnisearch = search_string
        },
        SET_PORTALNAV (state, nav_bool) {
            state.portalNavigation = nav_bool
        },
        SET_POSITION (state, position_object) {
            Vue.set(state, 'position', position_object)
        },
        SET_PROPS (state, props_object) {
            Vue.set(state, 'props', props_object)
        },
        SET_SEARCH (state, search_bool) {
            state.search = search_bool
        },
        SET_SEARCHINDEX (state, index_number) {
            state.searchIndex = index_number
        },
        SET_SEARCHOMNI (state, active_bool) {
            state.searchOmni = active_bool
        },
        SET_SEARCHREADY (state, ready_bool) {
            state.searchReady = ready_bool
        },
        SET_SECTIONS (state, sections_array) {
            Vue.set(state, 'sectionArray', sections_array)
        },
        SET_SECTIONSSTRING (state, sections_string) {
            state.sectionsString = sections_string
        },
        SET_SELECTEDSITE (state, site_number) {
            state.selectedSite = site_number
            if (null !== site_number) {
                state.sites[site_number].clicked = true
            }
        },
        SET_SHARED (state, sites_string) {
            state.sharedSites = sites_string
        },
        SET_SITEARRAY (state, site_array) {
            Vue.set(state, 'siteArray', site_array)
        },
        SET_SITESLUGS (state, sites_object) {
            Vue.set(state, 'siteSlugs', sites_object)
        },
        SET_SITES (state, sites_object) {
            Vue.set(state, 'sites', sites_object)
        },
        SET_SITESSTRING (state, site_string) {
            state.sitesString = site_string
        },
        SET_SPONSORS (state, sponsors_string) {
            let sponsor_array = sponsors_string.split('|')
            for (let i = 0; i < sponsor_array.length; ++i) {
                let strParts = sponsor_array[i].split(';')
                let sponsor = {}
                if (strParts[0] && strParts[1] && strParts[2]) {
                    sponsor['title'] = strParts[0].trim()
                    sponsor['webUrl'] = strParts[1].trim()
                    sponsor['thumbnailMediumUrl'] = strParts[2].trim()
                    state.sponsors.push(sponsor)
                }
            }
            state.hasSponsors = true
        },
        SET_URL (state) {
            state.setUrl = !state.setUrl
        },
        SET_VIEWS (state, bools_object) {
            state.viewPlayer = bools_object.player
            state.viewBroadcasts = bools_object.broadcasts
            state.viewSites = bools_object.sites
            state.viewApps = bools_object.apps
            state.viewSchedules = bools_object.schedules
            state.viewNews = bools_object.news
        },
        SET_WHITELABELED (state, label_bool) {
            state.whiteLabeled = label_bool
        },
        SET_WIDGET (state, widget_object) {
            Vue.set(state, 'widget', widget_object)
            state.hasWidget = true
        },
        SET_WIDTH (state, width_number) {
            state.width = width_number
        },
        UPDATE_LAYOUT (state, settings_object) {
            Vue.set(state.layout[settings_object.index].broadcastRow, 0, settings_object.query)
        },
        UPDATE_SEARCHQUERIES (state, settings_object) {
            Vue.set(state.searchQueries, settings_object.index, settings_object.query)
        },
        UPDATE_SITEQUERIES (state, settings_object) {
            Vue.set(state.sites[settings_object.site].queries, settings_object.index, settings_object.query)
        }
    },
    actions: { // methods
        async NO_SITE ({state,commit,dispatch}, broadcast_number) {
            if (null !== state.props.forceselect) {
                if ('true' === state.props.forceselect) {
                    commit('SET_FORCESELECT', true)
                }
            }
            commit('SET_BROADCASTEMPTY', false)
            commit('SET_BROADCASTREADY', false)
            commit('SET_SEARCH', false)
            if (!state.firstLoad) {
                commit('SET_FIRSTLOAD', true)
            }
            if (state.mainLoaded == false) {
                dispatch('SELECT_MAIN', broadcast_number)
            } else {
                if (null !== broadcast_number) {
                    if (typeof(state.broadcastHistory[broadcast_number]) !== 'undefined') {
                        commit('SET_BROADCAST', state.broadcastHistory[broadcast_number])
                        commit('SET_URL')
                    } else {
                        dispatch('GET_BROADCASTS', {type: 'broadcast', broadcast: broadcast_number, update: false})
                    }
                } else if (!state.broadcastReady) {
                    if (!state.forceSelect) {
                        for (let i = 0; i < state.layout.length; ++i) {
                            if ('broadcast' === state.layout[i].type && typeof(state.layout[i].broadcastRow[0].response.broadcasts[0]) !== 'undefined') {
                                commit('SET_BROADCAST', state.layout[i].broadcastRow[0].response.broadcasts[0])
                                commit('SET_URL')
                                break
                            }
                        }
                    }
                    if (!state.broadcastReady) {
                        dispatch('NO_BROADCASTS')
                    }
                }
            }
            commit('SET_SELECTEDSITE', null)
        },
        SELECT_SITE ({state,commit,dispatch}, settings_object) {
            commit('SET_FORCESELECT', false)
            commit('SET_BROADCASTEMPTY', false)
            commit('SET_BROADCASTREADY', false)
            commit('SET_SEARCH', false)
            if (!state.firstLoad) {
                commit('SET_FIRSTLOAD', true)
            }
            if (null !== settings_object.broadcast_number) {
                if (typeof(state.broadcastHistory[settings_object.broadcast_number]) !== 'undefined') {
                    commit('SET_BROADCAST', state.broadcastHistory[settings_object.broadcast_number])
                    commit('SET_URL')
                } else {
                    dispatch('GET_BROADCASTS', {type: 'broadcast', broadcast: settings_object.broadcast_number, update: false})
                }
            }
            if (!state.sites[settings_object.site_number].clicked) {
                for (let i = 0; i < state.sites[settings_object.site_number].queries.length; ++i) {
                    dispatch('GET_BROADCASTS', {type:'site', site:settings_object.site_number, index:i, update:false})
                }
            } else if (!state.broadcastReady) {
                if (!state.forceSelect) {
                    for (let i = 0; i < state.sites[settings_object.site_number].queries.length; ++i) {
                        if (typeof(state.sites[settings_object.site_number].queries[i].response.broadcasts[0]) !== 'undefined') {
                            commit('SET_BROADCAST', state.sites[settings_object.site_number].queries[i].response.broadcasts[0])
                            commit('SET_URL')
                            break
                        }
                    }
                }
                if (!state.broadcastReady) {
                    dispatch('NO_BROADCASTS')
                }
            }
            commit('SET_SELECTEDSITE', settings_object.site_number)
        },
        SELECT_MAIN ({state,commit,dispatch}, broadcast_number) {
            if (!state.firstLoad) {
                commit('SET_FIRSTLOAD', true)
            }
            if (null !== broadcast_number) {
                if (typeof(state.broadcastHistory[broadcast_number]) !== 'undefined') {
                    commit('SET_BROADCAST', state.broadcastHistory[broadcast_number])
                    commit('SET_URL')
                } else {
                    dispatch('GET_BROADCASTS', {type: 'broadcast', broadcast: broadcast_number, update: false})
                }
            } else if (typeof(state.config.layout.liveLinearParams) !== 'undefined') {
                let now = new Date()
                let live_linear_broadcast = {
                    id: state.config.layout.liveLinearParams.proxyBroadcastId.toString(),
                    title: state.config.title + ' Live',
                    status: 'Streaming',
                    date: now.toISOString(),
                    description: '',
                    section_title: 'LIVE',
                    autoplay: 1
                }
                commit('SET_BROADCAST', live_linear_broadcast)
                commit('SET_URL')
            }
            for (let i = 0; i < state.layout.length; ++i) {
                if ('broadcast' === state.layout[i].type) {
                    dispatch('GET_BROADCASTS', {type:'layout', index:i, update:false})
                }
            }
            if (0 === state.queue && !state.broadcastReady) {
                dispatch('NO_BROADCASTS')
            }
            commit('SET_MAINLOADED', true)
        },
        NO_BROADCASTS ({commit}) {
            commit('SET_BROADCAST', {})
            commit('SET_URL')
        },
        GET_BROADCASTS ({state,commit,dispatch}, settings_object) {
            let query = {broadcastSearchParams:{}}
            let callbackName = 'getBroadcasts'
            switch(settings_object.type) {
                case 'layout':
                    callbackName += 'LayoutIndex'+settings_object.index
                    query = JSON.parse(JSON.stringify(state.layout[settings_object.index].broadcastRow[0]))
                    break
                case 'search':
                    callbackName += 'SearchIndex'+settings_object.index
                    query = JSON.parse(JSON.stringify(state.searchQueries[settings_object.index]))
                    break
                case 'site':
                    callbackName += 'Site'+settings_object.site+'Index'+settings_object.index
                    query = JSON.parse(JSON.stringify(state.sites[settings_object.site].queries[settings_object.index]))
                    break
                case 'broadcast':
                    callbackName += 'ID'+settings_object.broadcast
                    query.broadcastSearchParams.id = settings_object.broadcast
                    query.broadcastSearchParams.site_id = state.sitesString
                    query.broadcastSearchParams.include_deletions = 0
                    break
            }
            if (settings_object.update) {
                ++query.broadcastSearchParams.page
            }
            commit('INCREMENT_QUEUE')
            query.broadcastSearchParams.callbackQuery = 'callback'
            query.broadcastSearchParams.callbackName = callbackName
            Vue.jsonp('//'+state.config.vCloud.domain+'/api/viewer/broadcast', query.broadcastSearchParams).then( (response) => {
                if (settings_object.update) {
                    ++query.updates
                    query.response.broadcasts = query.response.broadcasts.concat(response.broadcasts)
                } else {
                    query.response = response
                }
                if (null !== state.props.excludebroadcasts) {
                    let exclude_broadcasts = state.props.excludebroadcasts.split(',')
                    if (typeof(query.response.broadcasts) !== 'undefined') {
                        // Iterate in reverse order
                        for (let i = query.response.broadcasts.length - 1; i >= 0 ; --i) {
                            if (exclude_broadcasts.includes(query.response.broadcasts[i].id)) {
                                query.response.broadcasts.splice(i, 1)
                            }
                        }
                    }
                }
                settings_object.query = query
                switch(settings_object.type) {
                    case 'layout':
                        commit('UPDATE_LAYOUT', settings_object)
                        break
                    case 'search':
                        commit('UPDATE_SEARCHQUERIES', settings_object)
                        commit('SET_SEARCHREADY', true)
                        if (!state.broadcastReady && typeof(query.response.broadcasts[0]) !== 'undefined' && !state.forceSelect) {
                            commit('SET_BROADCAST', query.response.broadcasts[0])
                            commit('SET_URL')
                        }
                        break
                    case 'site':
                        commit('UPDATE_SITEQUERIES', settings_object)
                        break
                    case 'broadcast':
                        if (typeof(query.response.broadcasts[0]) !== 'undefined') {
                            commit('SET_BROADCAST', query.response.broadcasts[0])
                            commit('SET_URL')
                        }
                        break
                }
                commit('DECREMENT_QUEUE')
                if (0 === state.queue && !state.broadcastReady) {
                    if (!state.forceSelect) {
                        if (null === state.selectedSite) {
                            for (let i = 0; i < state.layout.length; ++i) {
                                if (typeof(state.layout[i].broadcastRow) !== 'undefined'
                                    && typeof(state.layout[i].broadcastRow[0].response) !== 'undefined'
                                    && typeof(state.layout[i].broadcastRow[0].response.broadcasts) !== 'undefined'
                                    && state.layout[i].broadcastRow[0].response.broadcasts.length > 0) {
                                        commit('SET_BROADCAST', state.layout[i].broadcastRow[0].response.broadcasts[0])
                                        commit('SET_URL')
                                        break
                                }
                            }
                        } else {
                            if (typeof(state.sites[state.selectedSite]) !== 'undefined'
                                && typeof(state.sites[state.selectedSite].queries) !== 'undefined') {
                                    for (let i = 0; i < state.sites[state.selectedSite].queries.length; ++i) {
                                        if (typeof(state.sites[state.selectedSite].queries[i].response.broadcasts[0]) !== 'undefined') {
                                            commit('SET_BROADCAST', state.sites[state.selectedSite].queries[i].response.broadcasts[0])
                                            commit('SET_URL')
                                            break
                                        }
                                    }
                                }
                        }
                    }
                    if (!state.broadcastReady) {
                        dispatch('NO_BROADCASTS')
                    }
                }
            }).catch( (error) => {
                commit('SET_ERROR', error)
            })
        },
        GET_VSS_BROADCAST ({state,commit}, vss_id) {
            Vue.jsonp('//'+state.config.vCloud.domain+'/api/viewer/videoSourceSchedule/currentBroadcast', {
                id: vss_id,
                callbackQuery: 'callback',
                callbackName: 'getVSSBroadcast'+vss_id,
            }).then( (response) => {
                if (typeof(response.video_source_schedule) !== 'undefined') {
                    if (null !== response.broadcast) {
                        commit('SET_BROADCAST', response.broadcast)
                        commit('SET_URL')
                        if (this.getters.mobile)
                        {
                            commit('SET_VIEWS', { player: true, broadcasts: true, sites: false, apps: false, schedules: false, news: false })
                        }
                        window.scroll({ top: state.position.y, left: state.position.x, behavior: 'smooth' })

                    } else {
                        alert(response.message)
                    }
                } else {
                    commit('SET_ERROR', response.error.message)
                }
            }).catch( (error) => {
                commit('SET_ERROR', error)
            })
        },
        GET_NEWS ({state,dispatch}, params) {
            return new Promise( async (resolve, reject) => {
                let url = '//'+state.config.vCloud.domain+'/api/viewer/app/rss?url='+encodeURI(params.url)
                await dispatch('GET_XMLHTTP', url).then( (response) => {
                    let response_obj = JSON.parse(response)
                    if (typeof(response_obj.contents) !== 'undefined' && response_obj.contents.indexOf('<rss') >= 0) {
                        let rss = response_obj.contents.slice(response_obj.contents.indexOf('<rss'))
                        let feed = { items: [] }
                        feed.title = params.title
                        let xml_object = convert.xml2js(rss, {compact:true})
                        let items = xml_object.rss.channel.item
                        for (let i = 0; i < items.length; ++i) {
                            let item = {}
                            if (typeof(items[i].title) !== 'undefined') {
                                if (typeof(items[i].title._text) !== 'undefined') {
                                    item.title = items[i].title._text
                                } else if (typeof(items[i].title._cdata) !== 'undefined') {
                                    item.title = items[i].title._cdata
                                } else {
                                    item.title = ''
                                }
                            } else {
                                item.title = ''
                            }
                            if (typeof(items[i].pubDate) !== 'undefined') {
                                if (typeof(items[i].pubDate._text) !== 'undefined') {
                                    item.pubDate = items[i].pubDate._text
                                } else if (typeof(items[i].pubDate._cdata) !== 'undefined') {
                                    item.pubDate = items[i].pubDate._cdata
                                } else {
                                    item.pubDate = ''
                                }
                            } else {
                                item.pubDate = ''
                            }
                            if (typeof(items[i].description) !== 'undefined') {
                                if (typeof(items[i].description._text) !== 'undefined') {
                                    item.description = items[i].description._text.replace(/<[^>]*>/g, '').trim()
                                } else if (typeof(items[i].description._cdata) !== 'undefined') {
                                    item.description = items[i].description._cdata.replace(/<[^>]*>/g, '').trim()
                                } else {
                                    item.description = ''
                                }
                            } else {
                                item.description = ''
                            }
                            if (typeof(items[i].link) !== 'undefined') {
                                if (typeof(items[i].link._text) !== 'undefined') {
                                    item.link = items[i].link._text
                                } else if (typeof(items[i].link._cdata) !== 'undefined') {
                                    item.link = items[i].link._cdata
                                } else {
                                    item.link = null
                                }
                            } else {
                                item.link = null
                            }
                            let imageParts = []
                            if (typeof(items[i].image) !== 'undefined') {
                                if (typeof(items[i].image._text) !== 'undefined') {
                                    imageParts = items[i].image._text.split('image_path=')
                                } else if (typeof(items[i].image._cdata) !== 'undefined') {
                                    imageParts = items[i].image._cdata.split('image_path=')
                                } else if (typeof(items[i].image.url) !== 'undefined') {
                                    if (typeof(items[i].image.url._text) !== 'undefined') {
                                        imageParts = items[i].image.url._text.split('image_path=')
                                    } else if (typeof(items[i].image.url._cdata) !== 'undefined') {
                                        imageParts = items[i].image.url._cdata.split('image_path=')
                                    }
                                } else if (typeof(items[i].image._attributes) !== 'undefined') {
                                    if (typeof(items[i].image._attributes.url) !== 'undefined') {
                                        imageParts = items[i].image._attributes.url.split('image_path=')
                                    }
                                }
                            } else if (typeof(items[i].enclosure) !== 'undefined') {
                                if (typeof(items[i].enclosure._text) !== 'undefined') {
                                    imageParts = items[i].enclosure._text.split('image_path=')
                                } else if (typeof(items[i].enclosure._cdata) !== 'undefined') {
                                    imageParts = items[i].enclosure._cdata.split('image_path=')
                                } else if (typeof(items[i].enclosure.url) !== 'undefined') {
                                    if (typeof(items[i].enclosure.url._text) !== 'undefined') {
                                        imageParts = items[i].enclosure.url._text.split('image_path=')
                                    } else if (typeof(items[i].enclosure.url._cdata) !== 'undefined') {
                                        imageParts = items[i].enclosure.url._cdata.split('image_path=')
                                    }
                                } else if (typeof(items[i].enclosure._attributes) !== 'undefined') {
                                    if (typeof(items[i].enclosure._attributes.url) !== 'undefined') {
                                        imageParts = items[i].enclosure._attributes.url.split('image_path=')
                                    }
                                }
                            }
                            if (imageParts.length > 1) {
                                let urlParts = imageParts[0].split('/')
                                item.image = urlParts[0] + '//' + urlParts[2] + imageParts[1]
                            } else if (imageParts.length === 1) {
                                item.image = imageParts[0]
                            } else {
                                item.image = ''
                            }
                            feed.items.push(item)
                        }
                        resolve(feed)
                    }
                    reject('Undefined response')
                }).catch( (error) => {
                    reject(error)
                })
            })
        },
        BUILD_SCHEDULEROW({dispatch}, row) {
            return new Promise( async (resolve, reject) => {
                let query = '?'
                if (typeof(row.videoSourceScheduleSearchParams.customer) !== 'undefined') {
                    query += 'customers='+row.videoSourceScheduleSearchParams.customer.join()+'&'
                }
                if (typeof(row.videoSourceScheduleSearchParams.site) !== 'undefined') {
                    query += 'sites='+row.videoSourceScheduleSearchParams.site.join()+'&'
                }
                if (typeof(row.videoSourceScheduleSearchParams.section) !== 'undefined') {
                    query += 'sections='+row.videoSourceScheduleSearchParams.section.join()+'&'
                }
                if (typeof(row.videoSourceScheduleSearchParams.video_source_id) !== 'undefined') {
                    query += 'video_source_ids='+row.videoSourceScheduleSearchParams.video_source_id.join()+'&'
                }
                if (typeof(row.videoSourceScheduleSearchParams.sortBy) !== 'undefined') {
                    query += 'sort_by='+row.videoSourceScheduleSearchParams.sortBy+'&'
                }
                if (typeof(row.videoSourceScheduleSearchParams.sortDir) !== 'undefined') {
                    query += 'sort_dir='+row.videoSourceScheduleSearchParams.sortDir+'&'
                }
                query = query.slice(0,-1)

                if (query.length > 0) {
                    await dispatch('GET_SCHEDULES', {query: query, page_num: 1, schedules: []}).then( (schedules) => {
                        if (null !== schedules) {
                            if (Array.isArray(schedules)) {
                                let valid = false
                                for (let i = 0; i < schedules.length; ++i) {
                                    if (null !== schedules[i]) {
                                        valid = true
                                    }
                                }
                                if (valid) {
                                    let scheduleRow = {}
                                    scheduleRow.title = row.title
                                    scheduleRow.schedules = schedules
                                    resolve(scheduleRow)
                                }
                            }
                        }
                        reject('No Video Source Schedules found.')
                    }).catch( (error) => {
                        reject(error)
                    })
                } else {
                    reject('No params set for VideoSourceSchedules.')
                }
            })
        },
        BUILD_SITEROW ({state}, row) {
            return new Promise( async (resolve) => {
                let siteRow = {}
                siteRow.title = row.title
                let sites = []
                siteRow.allowEdit = true
                if (row.siteSearchParams.defaultSiteIds.length > 0) {
                    siteRow.allowEdit = false
                    for (let i = 0; i < row.siteSearchParams.defaultSiteIds.length; ++i) {
                        if (row.siteSearchParams.defaultSiteIds[i] in state.sites) {
                            sites.push({id: row.siteSearchParams.defaultSiteIds[i], default: true})
                        }
                    }
                }
                if (row.siteSearchParams.appendFavoriteSites) {
                    siteRow.allowEdit = true
                    for (let i = 0; i < state.siteArray.length; ++i) {
                        sites.push({id: state.siteArray[i].id, default: state.sites[state.siteArray[i].id].default})
                    }
                }
                let foundSites = {}
                for (let i = 0; i < sites.length; ++i) {
                    if (typeof(foundSites[sites[i].id]) !== 'undefined') {
                        sites.splice(i,1)
                        --i
                    } else {
                        foundSites[sites[i].id] = i
                        sites[i].title = state.sites[sites[i].id].title
                        sites[i].image = state.sites[sites[i].id].image
                        if (siteRow.allowEdit) {
                            if ( sites[i].default ) {
                                sites[i].display = true
                            } else {
                                if (sites[i].id in state.cookies) {
                                    sites[i].display = false
                                } else {
                                    sites[i].display = true
                                }
                            }
                        } else {
                            sites[i].display = true
                        }
                    }
                }

                let sortedSites = []
                for (let i = 0; i < sites.length; ++i) {
                    if (sites[i].default) {
                        sortedSites.push(sites.splice(i,1)[0])
                        --i
                    }
                }

                let reA = /[^a-zA-Z]/g
                let reN = /[^0-9]/g

                function sortAlphaNum(a, b) {
                    if (isNaN(a) && isNaN(b)) {
                        let aA = a.replace(reA, '')
                        let bA = b.replace(reA, '')
                        if (aA === bA) {
                            let aN = parseInt(a.replace(reN, ''), 10)
                            let bN = parseInt(b.replace(reN, ''), 10)
                            return aN === bN ? 0 : aN > bN ? 1 : -1
                        } else {
                            return aA > bA ? 1 : -1
                        }
                    } else {
                        return a > b ? 1 : -1
                    }
                }

                function compareValues(key, order) {
                    return function(a, b) {
                        const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key]
                        const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key]
                        let comparison = sortAlphaNum(varA, varB)
                        return (order === 'desc')? (comparison * -1) : comparison
                    }
                }

                if (typeof(row.siteSearchParams.sortBy) !== 'undefined') {
                    if (sortedSites.length > 1) {
                        sortedSites.sort(compareValues(row.siteSearchParams.sortBy,row.siteSearchParams.sortDir))
                    }
                    sites.sort(compareValues(row.siteSearchParams.sortBy,row.siteSearchParams.sortDir))
                } else {
                    if (sortedSites.length > 1) {
                        sortedSites.sort(compareValues('title','asc'))
                    }
                    sites.sort(compareValues('title','asc'))
                }
                siteRow.sites = sortedSites.concat(sites)
                resolve(siteRow)
            })
        },
        BUILD_SCHEDULE_QUERY (context, params) {
            return new Promise( (resolve) => {
                let query = '?'
                if (typeof(params.customer) !== 'undefined') {
                    query += 'customers='+params.customer.join()+'&'
                }
                if (typeof(params.site) !== 'undefined') {
                    query += 'sites='+params.site.join()+'&'
                }
                if (typeof(params.section) !== 'undefined') {
                    query += 'sections='+params.section.join()+'&'
                }
                if (typeof(params.video_source_id) !== 'undefined') {
                    query += 'video_source_ids='+params.video_source_id.join()+'&'
                }
                if (typeof(params.sortBy) !== 'undefined') {
                    query += 'sort_by='+params.sortBy+'&'
                }
                if (typeof(params.sortDir) !== 'undefined') {
                    query += 'sort_dir='+params.sortDir+'&'
                }
                query = query.slice(0,-1)
                resolve(query)
            })
        },
        BUILD_QUERY ({state}, params) {
            return new Promise( (resolve) => {
                let query = { broadcastSearchParams:{} }
                query.updates = 0
                query.response = {}
                query.response.broadcasts = []
                query.broadcastSearchParams.include_deletions = 0
                query.broadcastSearchParams.autoplay = 0
                query.broadcastSearchParams.page = 1
                query.broadcastSearchParams.per_page = 25
                query.title = params.row.title
                if (params.includeSites) {
                    if (typeof(params.row.broadcastSearchParams.siteIds) !== 'undefined') {
                        query.broadcastSearchParams.site_id = params.row.broadcastSearchParams.siteIds.join()
                    } else {
                        query.broadcastSearchParams.site_id = state.sitesString
                    }
                }
                if (typeof(params.row.broadcastSearchParams.sectionIds) !== 'undefined') {
                    query.broadcastSearchParams.section_id = params.row.broadcastSearchParams.sectionIds.join()
                } else if (state.sectionsString.length > 0) {
                    query.broadcastSearchParams.section_id = state.sectionsString
                }
                if (typeof(params.row.broadcastSearchParams.viewerStatus) !== 'undefined') {
                    query.broadcastSearchParams.viewer_status = params.row.broadcastSearchParams.viewerStatus
                } else if (typeof(params.row.broadcastSearchParams.list) !== 'undefined') {
                    query.broadcastSearchParams.list = params.row.broadcastSearchParams.list
                }
                if (typeof(params.row.broadcastSearchParams.playlistId) !== 'undefined') {
                    query.broadcastSearchParams.playlist_id = params.row.broadcastSearchParams.playlistId
                }
                if (typeof(params.row.broadcastSearchParams.scheduleId) !== 'undefined') {
                    query.broadcastSearchParams.video_source_schedule_id = params.row.broadcastSearchParams.scheduleId
                }
                if (typeof(params.row.broadcastSearchParams.sortBy) !== 'undefined') {
                    query.broadcastSearchParams.sort_by = params.row.broadcastSearchParams.sortBy
                }
                if (typeof(params.row.broadcastSearchParams.sortDir) !== 'undefined') {
                    query.broadcastSearchParams.sort_dir = params.row.broadcastSearchParams.sortDir
                }
                resolve(query)
            })
        },
        LOAD_LAYOUT ({state,commit,dispatch}) {
            return new Promise( async (resolve) => {
                if (typeof(state.config.layout.logoUrl) !== 'undefined') {
                    commit('SET_LOGOURL', state.config.layout.logoUrl)
                }
                for (let i = 0; i < state.config.layout.rows.length; ++i) {
                    let row = state.config.layout.rows[i]
                    let layout_object = {}
                    layout_object.type = row.type
                    switch (layout_object.type) {
                        case 'broadcast':
                            await dispatch('BUILD_QUERY', {row:row, includeSites:true}).then( (query) => {
                                layout_object.broadcastRow = [ query ]
                                commit('ADD_LAYOUT', layout_object)
                            })
                            break
                        case 'site':
                            await dispatch('BUILD_SITEROW', row).then( (siteRow) => {
                                layout_object.siteRow = siteRow
                                commit('ADD_LAYOUT', layout_object)
                            })
                            break
                        case 'embeddedApp':
                            layout_object.appRow = row
                            commit('ADD_LAYOUT', layout_object)
                            break
                        case 'videoSourceSchedule':
                            await dispatch('BUILD_SCHEDULEROW', row).then( (scheduleRow) => {
                                layout_object.scheduleRow = scheduleRow
                                commit('ADD_LAYOUT', layout_object)
                            }).catch( (error) => {
                                commit('SET_ERROR', error)
                            })
                            break
                        case 'news':
                            await dispatch('GET_NEWS', {url:row.newsParams.networkRssUrl, title:row.title}).then( (feed) => {
                                layout_object.newsRow = feed
                                commit('ADD_LAYOUT', layout_object)
                            }).catch( (error) => {
                                commit('SET_ERROR', error)
                            })
                            break
                        case 'sponsors':
                            layout_object.sponsorsRow = row
                            commit('ADD_LAYOUT', layout_object)
                            break
                        case 'settings':
                            if (typeof(row.settingsParams) !== 'undefined') {
                                if (typeof(row.settingsParams.filters) !== 'undefined') {
                                    commit('SET_FILTERS', row.settingsParams.filters)
                                }
                            }
                            break
                    }
                }
                commit('SET_LAYOUTLOADED', true)
                resolve()
            })
        },
        GET_SCHEDULES ({state,dispatch}, params) {
            return new Promise( async (resolve, reject) => {
                let query = params.query += '&page='+params.page_num
                let schedules = params.schedules
                Vue.jsonp('//'+state.config.vCloud.domain+'/api/viewer/videoSourceSchedule'+query, {
                    callbackQuery: 'callback',
                    callbackName: 'getSchedulesPage'+params.page_num,
                }).then( async (response) => {
                    schedules = schedules.concat(response.video_source_schedules)
                    if ((typeof(response.num_pages) !== 'undefined') && (response.num_pages > response.page)) {
                        let page_num = params.page_num + 1
                        await dispatch('GET_SCHEDULES', {query: query, page_num: page_num, schedules: schedules})
                    } else {
                        resolve(schedules)
                    }
                }).catch( (error) => {
                    reject(error)
                })
            })
        },
        GET_SITES ({state,commit,dispatch}, params) {
            return new Promise( async (resolve, reject) => {
                let query = '?site_ids='+state.sitesString+'&per_page=100&page='+params.page_num
                let sites_array = params.sites_array
                Vue.jsonp('//'+state.config.vCloud.domain+'/api/viewer/site'+query, {
                    callbackQuery: 'callback',
                    callbackName: 'getSitesPage'+params.page_num,
                }).then( async (response) => {
                    sites_array = sites_array.concat(response.sites)
                    if (response.num_pages > response.page) {
                        let page_num = params.page_num + 1
                        await dispatch('GET_SITES', {page_num: page_num, sites_array: sites_array})
                    } else {
                        let sites = {}
                        let siteSlugs = {}
                        let siteArray = []
                        for (let i = 0; i < sites_array.length; ++i) {
                            let site = { id: sites_array[i].id, title: sites_array[i].title.trim(), image: sites_array[i].medium_image, slug: sites_array[i].slug }
                            siteArray.push( JSON.parse(JSON.stringify(site)) )
                            siteSlugs[site.slug.toLowerCase()] = site.id
                            site.clicked = false
                            site.default = false
                            sites[site.id] = site
                        }
                        commit('SET_SITESLUGS', siteSlugs)
                        commit('SET_SITEARRAY', siteArray)
                        let queries_template = []
                        for (let i = 0; i < state.config.siteLayout.rows.length; ++i) {
                            let row = state.config.siteLayout.rows[i]
                            if ('broadcast' === row.type) {
                                await dispatch('BUILD_QUERY', {row:row, includeSites:false}).then( (query) => {
                                    queries_template.push(query)
                                })
                            }
                        }
                        let siteIDs = Object.keys(sites)
                        for (let i = 0; i < siteIDs.length; ++i) {
                            let queries = JSON.parse(JSON.stringify(queries_template))
                            for (let j = 0; j < queries.length; ++j) {
                                if (null !== state.sharedSites
                                    && null !== state.props.portalsite
                                    && typeof(siteSlugs[state.props.portalsite.toLowerCase()]) != 'undefined'
                                    && siteSlugs[state.props.portalsite.toLowerCase()] == siteIDs[i])
                                {
                                    queries[j].broadcastSearchParams.site_id = siteIDs[i] + ',' + state.sharedSites
                                }
                                else
                                {
                                    queries[j].broadcastSearchParams.site_id = siteIDs[i]
                                }
                            }
                            sites[siteIDs[i]].queries = queries
                        }
                        commit('SET_SITES', sites)
                    }
                    resolve()
                }).catch( (error) => {
                    commit('SET_ERROR', error)
                    reject(error)
                })
            })
        },
        GET_SECTIONS ({state,commit,dispatch}, params) {
            return new Promise( async (resolve, reject) => {
                let query = '?'+params.query+'&per_page=100&page='+params.page_num
                let sections = params.sections
                Vue.jsonp('//'+state.config.vCloud.domain+'/api/viewer/section'+query, {
                    callbackQuery: 'callback',
                    callbackName: 'getSectionsPage'+params.page_num,
                }).then( async (response) => {
                    sections = sections.concat(response.sections)
                    if (response.num_pages > response.page) {
                        let page_num = params.page_num + 1
                        await dispatch('GET_SECTIONS', {query: params.query, page_num: page_num, sections: sections})
                    } else {
                        let sectionArray = []
                        let sectionIds = []
                        for (let i = 0; i < sections.length; ++i) {
                            let title = sections[i].title.trim()
                            if ('test' !== title.toLowerCase())
                            {
                                sectionArray.push({ id: sections[i].id, title: title })
                                sectionIds.push(sections[i].id)
                            }
                        }
                        commit('SET_SECTIONS', sectionArray)
                        commit('SET_SECTIONSSTRING', sectionIds.join())
                    }
                    resolve()
                }).catch( (error) => {
                    commit('SET_ERROR', error)
                    reject(error)
                })
            })
        },
        LOAD_SITES_AND_SECTIONS ({state,commit,dispatch}) {
            return new Promise( async (resolve) => {

                let site_ids = []
                if (typeof(state.config.vCloud.siteIds) !== 'undefined') {
                    site_ids = state.config.vCloud.siteIds
                }
                if (null !== state.props.excludesites) {
                    let exclude_sites = state.props.excludesites.split(',')
                    for (let i = 0; i < exclude_sites.length; ++i) {
                        let remove_index = site_ids.indexOf(parseInt(exclude_sites[i]))
                        if (remove_index > -1) {
                            site_ids.splice(remove_index, 1)
                        }
                    }
                }
                commit('SET_SITESSTRING', site_ids.join())

                let first_query = ''
                if (typeof(state.config.vCloud.sectionIds) !== 'undefined') {
                    first_query += 'section_ids='+state.config.vCloud.sectionIds.join()
                } else {
                    first_query += 'site_id='+state.sitesString
                }

                await dispatch('GET_SECTIONS', {query: first_query, page_num: 1, sections: []}).then( async () => {
                    let section_ids = state.sectionsString.split(',')
                    let sections_changed = false
                    if (null !== state.props.portalsections) {
                        let portal_sections = state.props.portalsections.split(',')
                        for (let i = 0; i < portal_sections.length; ++i) {
                            section_ids.push(portal_sections[i])
                        }
                        sections_changed = true
                    }
                    if (null !== state.props.excludesections) {
                        let exclude_sections = state.props.excludesections.split(',')
                        for (let i = 0; i < exclude_sections.length; ++i) {
                            let remove_index = section_ids.indexOf(parseInt(exclude_sections[i]))
                            if (remove_index > -1) {
                                section_ids.splice(remove_index, 1)
                                sections_changed = true
                            }
                        }
                    }
                    if (sections_changed) {
                        let query = ''
                        if (section_ids.length > 0) {
                            query += 'section_ids='+section_ids.join()
                        } else if (state.sitesString.length > 0) {
                            query += 'site_id='+state.sitesString
                        }
                        await dispatch('GET_SECTIONS', {query: query, page_num: 1, sections: []})
                    }

                    await dispatch('GET_SITES', {page_num: 1, sites_array: []})
                    resolve()
                })
            })
        },
        LOAD_WIDGET_SECTIONS ({state,commit}) {
            return new Promise( (resolve) => {
                let sectionArray = []
                let sectionIds = []
                for (let i = 0; i < state.widget.widget.sections.length; ++i) {
                    let title = state.widget.widget.sections[i].title.trim()
                    if ('test' !== title.toLowerCase())
                    {
                        sectionArray.push({ id: state.widget.widget.sections[i].id, title: title })
                        sectionIds.push(state.widget.widget.sections[i].id)
                    }
                }
                commit('SET_SECTIONS', sectionArray)
                commit('SET_SECTIONSSTRING', sectionIds.join())
                resolve()
            })
        },
        LOAD_WIDGET_SITES ({state,commit,dispatch}) {
            return new Promise( async (resolve) => {
                let site_ids = state.widget.widget.associated_sites
                let exclude_sites = []
                if (null !== state.props.excludesites) {
                    exclude_sites = state.props.excludesites.split(',')
                    for (let i = 0; i < exclude_sites.length; ++i) {
                        let remove_index = site_ids.indexOf(exclude_sites[i])
                        if (remove_index > -1) {
                            site_ids.splice(remove_index, 1)
                        }
                    }
                }
                commit('SET_SITESSTRING', site_ids.join())

                let sites = {}
                let siteSlugs = {}
                let siteArray = []
                let defaultSite = parseInt(state.widget.widget.default_site)
                for (let i = 0; i < state.widget.widget.sites.length; ++i) {
                    if (null !== state.props.excludesites) {
                        let remove_index = exclude_sites.indexOf(state.widget.widget.sites[i].id.toString())
                        if (remove_index > -1) {
                            continue
                        }
                    }
                    let site = { id: state.widget.widget.sites[i].id, title: state.widget.widget.sites[i].title.trim(), image: state.widget.widget.sites[i].medium_image, slug: state.widget.widget.sites[i].slug }
                    siteArray.push( JSON.parse(JSON.stringify(site)) )
                    siteSlugs[site.slug.toLowerCase()] = site.id
                    site.clicked = false
                    site.default = false
                    if (defaultSite === site.id) {
                        site.default = true
                    }
                    sites[site.id] = site
                }

                commit('SET_SITESLUGS', siteSlugs)
                commit('SET_SITEARRAY', siteArray)

                let queries_template = []
                for (let i = 0; i < state.config.siteLayout.rows.length; ++i) {
                    let row = state.config.siteLayout.rows[i]
                    if ('broadcast' === row.type) {
                        await dispatch('BUILD_QUERY', {row:row, includeSites:false}).then( (query) => {
                            queries_template.push(query)
                        })
                    }
                }

                let siteIDs = Object.keys(sites)
                for (let i = 0; i < siteIDs.length; ++i) {
                    let queries = JSON.parse(JSON.stringify(queries_template))
                    for (let j = 0; j < queries.length; ++j) {
                        if (null !== state.sharedSites
                            && null !== state.props.portalsite
                            && typeof(siteSlugs[state.props.portalsite.toLowerCase()]) != 'undefined'
                            && siteSlugs[state.props.portalsite.toLowerCase()] == siteIDs[i])
                        {
                            queries[j].broadcastSearchParams.site_id = siteIDs[i] + ',' + state.sharedSites
                        }
                        else
                        {
                            queries[j].broadcastSearchParams.site_id = siteIDs[i]
                        }
                    }
                    sites[siteIDs[i]].queries = queries
                }
                commit('SET_SITES', sites)
                resolve()
            })
        },
        LOAD_WIDGET_FILTERS ({state,commit}) {
            return new Promise( (resolve) => {
                let filters = {showSitesFilter: false, showSectionsFilter: false, showDateFilter: false, showTitleFilter: false}
                if (typeof(state.widget.layout.showSitesFilter) !== 'undefined') {
                    filters.showSitesFilter = true
                }
                if (typeof(state.widget.layout.showSportsFilter) !== 'undefined') {
                    filters.showSectionsFilter = true
                }
                if (typeof(state.widget.layout.showDateFilter) !== 'undefined') {
                    filters.showDateFilter = true
                }
                if (typeof(state.widget.layout.showTitleFilter) !== 'undefined') {
                    filters.showTitleFilter = true
                }
                commit('SET_FILTERS', filters)
                resolve()
            })
        },
        LOAD_COOKIES ({state,commit}) {
            let cookies = {}
            if (document.cookie.length > 0) {
                let cookieArray = document.cookie.split('; ')
                for (let i = 0; i < cookieArray.length; ++i) {
                    let cookie = cookieArray[i].split('=')
                    let name = cookie[0].split(':')
                    if (name[0] === state.configLink && cookie[1] === 'hidden') {
                        cookies[parseInt(name[1])] = true
                    }
                }
            }
            commit('SET_COOKIES', cookies)
        },
        LOAD_WHITELABELED ({state,commit}) {
            if (typeof(state.config.whiteLabeled) !== 'undefined') {
                commit('SET_WHITELABELED', state.config.whiteLabeled)
            }
        },
        LOAD_LINKS ({state,commit}) {
            if (typeof(state.config.links) !== 'undefined') {
                let links = state.config.links
                if (links.length > 0) {
                    commit('SET_LINKS', links)
                }
            }
        },
        SET_SECONDARY ({commit}, colorHex) {
            let root = document.documentElement
            root.style.setProperty('--v-secondary-base', colorHex)
            root.style.setProperty('--v-secondary-lighten5', tinycolor(colorHex).lighten(50).toString())
            root.style.setProperty('--v-secondary-lighten4', tinycolor(colorHex).lighten(40).toString())
            root.style.setProperty('--v-secondary-lighten3', tinycolor(colorHex).lighten(30).toString())
            root.style.setProperty('--v-secondary-lighten2', tinycolor(colorHex).lighten(20).toString())
            root.style.setProperty('--v-secondary-lighten1', tinycolor(colorHex).lighten(10).toString())
            root.style.setProperty('--v-secondary-darken1', tinycolor(colorHex).darken(10).toString())
            root.style.setProperty('--v-secondary-darken2', tinycolor(colorHex).darken(20).toString())
            root.style.setProperty('--v-secondary-darken3', tinycolor(colorHex).darken(30).toString())
            root.style.setProperty('--v-secondary-darken4', tinycolor(colorHex).darken(40).toString())
            commit('SET_COLORSECONDARY', colorHex)
        },
        SET_PRIMARY ({commit}, colorHex) {
            let root = document.documentElement
            root.style.setProperty('--v-primary-base', colorHex)
            root.style.setProperty('--v-primary-lighten5', tinycolor(colorHex).lighten(50).toString())
            root.style.setProperty('--v-primary-lighten4', tinycolor(colorHex).lighten(40).toString())
            root.style.setProperty('--v-primary-lighten3', tinycolor(colorHex).lighten(30).toString())
            root.style.setProperty('--v-primary-lighten2', tinycolor(colorHex).lighten(20).toString())
            root.style.setProperty('--v-primary-lighten1', tinycolor(colorHex).lighten(10).toString())
            root.style.setProperty('--v-primary-darken1', tinycolor(colorHex).darken(10).toString())
            root.style.setProperty('--v-primary-darken2', tinycolor(colorHex).darken(20).toString())
            root.style.setProperty('--v-primary-darken3', tinycolor(colorHex).darken(30).toString())
            root.style.setProperty('--v-primary-darken4', tinycolor(colorHex).darken(40).toString())
            commit('SET_COLORPRIMARY', colorHex)
        },
        LOAD_POSITION ({state,commit}, element) {
            let blueframe = document.getElementById(element)
            let x = 0
            let y = 0
            while (blueframe !== null) {
                x += blueframe.offsetLeft
                y += blueframe.offsetTop
                blueframe = blueframe.offsetParent
            }
            commit('SET_POSITION', { x: x, y: (y - state.offset) })
        },
        LOAD_PROPS ({state,commit,dispatch}) {
            // Theme
            if ('light' === state.props.portaltheme) {
                commit('SET_DARK', false)
            }

            // Colors
            if (null !== state.props.colorprimary) {
                dispatch('SET_PRIMARY', state.props.colorprimary)
            }
            if (null !== state.props.colorsecondary) {
                dispatch('SET_SECONDARY', state.props.colorsecondary)
            }

            // Portal extras
            if (null !== state.props.portaloffset) {
                commit('SET_OFFSET', parseInt(state.props.portaloffset))
            }
            if (null !== state.props.portalmode) {
                commit('SET_MODE', state.props.portalmode)
            }
            if ('true' === state.props.portalnav) {
                commit('SET_PORTALNAV', true)
            }
            if ('true' === state.props.omni) {
                commit('SET_SEARCHOMNI', true)
            }
            if ('true' === state.props.fullsearch) {
                commit('SET_FULLSEARCH', true)
            }
            if (null !== state.props.portalsponsors) {
                commit('SET_SPONSORS', state.props.portalsponsors)
            }
            if (null !== state.props.sharedsites) {
                commit('SET_SHARED', state.props.sharedsites)
            }
        },
        async LOAD_CONFIG ({state,commit,dispatch}) {
            // Config Colors
            if (null === state.props.colorprimary && typeof(state.config.primaryThemeColor) !== 'undefined') {
                dispatch('SET_PRIMARY', state.config.primaryThemeColor)
            }
            if (null === state.props.colorsecondary && typeof(state.config.secondaryThemeColor) !== 'undefined') {
                dispatch('SET_SECONDARY', state.config.secondaryThemeColor)
            }

            // Background
            let blueframe = document.getElementById('blueframe')
            if ('true' !== state.props.portalbg) {
                if (typeof(state.config.layout.webBackgroundUrl) !== 'undefined') {
                    blueframe.style.backgroundImage = 'url("'+state.config.layout.webBackgroundUrl+'")'
                    blueframe.style.backgroundAttachment = 'fixed'
                    blueframe.style.backgroundSize = 'cover'
                } else if (state.dark) {
                    blueframe.classList.add('primary','lighten-1')
                } else {
                    blueframe.classList.add('transparent')
                }
            } else {
                blueframe.classList.add('transparent')
            }

            dispatch('LOAD_LINKS')
            dispatch('LOAD_WHITELABELED')
            dispatch('LOAD_COOKIES')

            commit('SET_CONFIGLOADED', true)

            // Sites/sections
            if (state.hasWidget) {
                await dispatch('LOAD_WIDGET_FILTERS')
                await dispatch('LOAD_WIDGET_SITES')
                await dispatch('LOAD_WIDGET_SECTIONS')
            } else {
                await dispatch('LOAD_SITES_AND_SECTIONS')
            }

            // Main layout
            await dispatch('LOAD_LAYOUT')

            // Select Broadcast
            let broadcast_number = null
            if (null !== state.props.portalbroadcast) {
                broadcast_number = state.props.portalbroadcast
            } else if (null === state.props.portalsite && null !== state.props.forceselect) {
                if ('true' === state.props.forceselect) {
                    commit('SET_FORCESELECT', true)
                }
            }

            if (null !== state.props.portalsite && typeof(state.siteSlugs[state.props.portalsite.toLowerCase()]) != 'undefined') {
                // Select Site
                dispatch('SELECT_SITE', {site_number: state.siteSlugs[state.props.portalsite.toLowerCase()], broadcast_number: broadcast_number})
            } else {
                // Select Main
                dispatch('SELECT_MAIN', broadcast_number)
            }
        },
        GET_WIDGET ({state,commit,dispatch}) {
            Vue.jsonp('//'+state.config.vCloud.domain+'/api/widget/info/index/'+state.config.vCloud.widgetId, {
                callbackQuery: 'callback',
                callbackName: 'getWidget'+state.config.vCloud.widgetId,
            }).then( (response) => {
                commit('SET_WIDGET', response)
                dispatch('LOAD_CONFIG')
            }).catch( (error) => {
                commit('SET_ERROR', error)
            })
        },
        GET_XMLHTTP (context, url) {
            return new Promise( (resolve, reject) => {
                let req = new XMLHttpRequest()
                req.open('GET', url)
                req.onload = function() {
                    if (req.status === 200) {
                        resolve(req.response)
                    } else {
                        reject(Error(req.statusText+' ('+req.status+')'))
                    }
                }
                req.onerror = function() {
                    reject(Error('Network Error'))
                }
                req.send()
            })
        },
        BUILD_CONFIGLINK ({state,commit}, url) {
            return new Promise( (resolve) => {
                let newUrl = url.replace(/^(https:|http:)/, '')
                let configParts = newUrl.split('/')
                let parentParts = state.configParent.split('/')
                while (configParts.length > 0 && parentParts.length > 0) {
                    if (configParts[0] === parentParts[0]) {
                        configParts.shift()
                        parentParts.shift()
                    } else {
                        break
                    }
                }
                for (let i = 1; i < parentParts.length; ++i) {
                    configParts.unshift('..')
                }
                if ('config.json' === configParts[configParts.length - 1]) {
                    configParts.pop()
                }
                commit('SET_CONFIGLINK', configParts.join('/'))
                resolve()
            })
        },
        async GET_CONFIG ({state,commit,dispatch}, params) {
            await dispatch('BUILD_CONFIGLINK', params.url)
            await dispatch('GET_XMLHTTP', params.url).then( (response) => {
                commit('SET_CONFIG', JSON.parse(response))
                if (typeof(state.config.vCloud.siteIds) !== 'undefined') {
                    dispatch('LOAD_CONFIG')
                } else {
                    dispatch('GET_WIDGET')
                }
            }).catch( (error) => {
                commit('SET_ERROR', error)
                if (params.attempt < 4) {
                    let retry = params.attempt * 3
                    ++params.attempt
                    setTimeout(() => {
                        dispatch('GET_CONFIG', params)
                    }, retry*1000)
                } else {
                    dispatch('NO_BROADCASTS')
                }
            })
        },
        CHANGE_CONFIG ({commit,dispatch}, url) {
            commit('SAVE_STATE', {id:location.href, all:true})
            commit('RESET_STATE')
            dispatch('GET_CONFIG', {url:url, attempt:1})
        }
    }
})

<template>
    <div v-if="showSearch" class="primary-bg" :style="searchStyle">
        <v-expansion-panel :class="searchPanelClass" dark :title="'Search '+labels.broadcastLabelPlural" :aria-label="'Search '+labels.broadcastLabelPlural">
            <v-expansion-panel-content :class="expansionPanelClass">
                <template v-slot:header>
                    <v-layout class="search-flex">
                        <v-icon color="rgba(255,255,255,0.7)">fo-search</v-icon>
                        <div class="search-header">Search {{ labels.broadcastLabelPlural.toLowerCase() }}</div>
                    </v-layout>
                </template>
                <template v-slot:actions>
                    <v-icon color="rgba(255,255,255,0.7)">$vuetify.icons.expand</v-icon>
                </template>
                <SearchControl />
                <div v-if="search" class="search-wrapper">
                    <div v-if="searchReady">
                        <BroadcastsRow type="search" :queries="searchQueries" :start="searchIndex" :end="searchIndex + 1" />
                    </div>
                    <div v-else class="spinner-search">
                        <v-progress-circular color="secondary lighten-3" :size="40" :width="3" indeterminate></v-progress-circular>
                    </div>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import SearchControl from './Search/SearchControl.vue'
import BroadcastsRow from './BroadcastsRow.vue'

export default {
    name: 'SearchRow',
    components: {
        SearchControl,
        BroadcastsRow
    },
    computed: {
        expansionPanelClass() {
            let expansionClass = 'primary-bg'
            if (this.fullSearch) {
                expansionClass += ' no-glow'
            }
            return expansionClass
        },
        searchPanelClass() {
            let searchClass = ''
            if (this.fullSearch) {
                searchClass += ' inner-div no-glow'
            }
            return searchClass
        },
        searchStyle() {
            if (this.fullSearch) {
                return {
                    'width': '100vw',
                    'position': 'relative',
                    'left': '50%',
                    'right': '50%',
                    'margin-left': '-50vw',
                    'margin-right': '-50vw',
                    'margin-bottom': '24px'
                }
            } else {
                return {
                    'margin-top': '24px'
                }
            }
        },
        ...mapState([
            'fullSearch',
            'labels',
            'search',
            'searchIndex',
            'searchQueries',
            'searchReady',
            'showSearch'
        ])
    }
}
</script>

<style>
.search-flex {
    display: flex;
    align-items: baseline;
    padding: 8px 0;
}
.search-header {
    color:rgba(255,255,255,0.7);
    margin-left:16px;
}
.spinner-search {
    margin: 135px 0;
}
.search-wrapper {
    text-align: center;
    padding: 0 12px;
}
.v-expansion-panel {
    list-style: none!important;
}
.v-expansion-panel__container {
    margin: 0!important;
}
</style>
<template>
    <div>
        <div class="swiper-wrapper">
            <div :ref="row.title" class="swiper-content" :id="'swiper-content-'+row.title" @scroll="scrollBehavior" @mousedown="clickDown($event)" @mouseleave="dragging = false" @mouseup="dragging = false" @mousemove="clickMove($event)">
                <div class="swiper-slide" v-for="(broadcast,index) in row.response.broadcasts" :key="index" :id="broadcast.id" @click="selectBroadcast(broadcast)" @keyup.enter.space="selectBroadcast(broadcast)" tabindex="0" role="button" aria-pressed="false" :title="linkText(broadcast)" :aria-label="linkText(broadcast)">
                    <div :style="slideImage(broadcast.medium_image)">

                        <span v-if="'no' != broadcast.require_login" class="screen-info-box"><v-icon small color="rgba(255,255,255,0.7)">fo-lock</v-icon></span>
                        <span v-if="'0' != broadcast.region_blocking" class="screen-info-box"><v-icon small color="rgba(255,255,255,0.7)">fo-globe</v-icon></span>

                        <span v-if="'Upcoming' == broadcast.status" class="screen-info-box bottom-icon">{{ date(broadcast.date) }}, {{ time(broadcast.date) }} &nbsp;</span>
                        <span v-else-if="'Streaming' == broadcast.status" class="screen-info-box bottom-icon live-text"><v-icon small color="red">fo-record</v-icon> Live now &nbsp;</span>
                        <span v-else class="screen-info-box bottom-icon">{{ date(broadcast.date) }} &nbsp;</span>

                    </div>
                    <div :class="(dark && 'search' === type? 'cell-title darken1-bg' : 'cell-title primary-bg')">
                        <div class="broadcast-title truncate-two" v-html="broadcast.title"></div>
                        <div class="site-text truncate" v-html="broadcast.site_title"></div>
                        <div class="section-text truncate" v-html="broadcast.section_title"></div>
                    </div>
                </div>
            </div>
            <div :ref="row.title+'prev'" class="swiper-button-prev" @click="scrollLeft" title="Navigate Back" aria-label="Navigate Back" tabindex="0" role="button" aria-pressed="false"></div>
            <div :ref="row.title+'next'" class="swiper-button-next" @click="scrollRight" title="Navigate Forward" aria-label="Navigate Forward" tabindex="0" role="button" aria-pressed="false"></div>
        </div>
        <div class="text-xs-right results-padding">
            <span v-if="updating"><v-progress-circular color="primary lighten-5" :size="15" :width="2" indeterminate></v-progress-circular></span>
            <small v-else>{{row.response.broadcasts.length}} of {{row.response.item_count}} results</small>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'SwiperControl',
    props: {
        type: {
            type: String,
            required: true
        },
        site: {
            type: Number,
            required: false,
            default: null
        },
        layoutIndex: {
            type: Number,
            required: true
        },
        row: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            dragged: false,
            dragging: false,
            slidesPerPage: this.mobile? (this.$store.state.width)/(312):(this.$store.state.width-24)/(312),
            startXPos: 0,
            updating: false
        }
    },
    computed: {
        imageHeight() {
            return (300 / 1.78)
        },
        queryUpdates() {
            return this.row.updates
        },
        ...mapState([
            'dark',
            'forceSelect',
            'position',
            'searchOmni',
            'width'
        ]),
        ...mapGetters([
            'mobile'
        ])
    },
    methods: {
        clickDown(event) {
            this.dragging = true
            this.dragged = false
            this.startXPos = event.clientX;
        },
        clickMove(event) {
            if (this.dragging)
            {
                event.preventDefault()
                this.$refs[this.row.title].scrollLeft = this.$refs[this.row.title].scrollLeft - ((event.clientX - this.startXPos)*2);
                this.startXPos = event.clientX;
                this.dragged = true
            }
        },
        date(broadcast_date) {
            let broadcastDate = new Date(broadcast_date)
            let dateOptions = {
                month: 'short',
                day: '2-digit',
                year: 'numeric'
            }
            return broadcastDate.toLocaleString('en-us', dateOptions)
        },
        longDate(broadcast_date) {
            let broadcastDate = new Date(broadcast_date)
            let weekday = broadcastDate.toLocaleString('en-us', { weekday: 'long' })
            let month = broadcastDate.toLocaleString('en-us', { month: 'long' })
            switch (month) {
                case 'January':
                    month = 'Jan.'
                    break
                case 'February':
                    month = 'Feb.'
                    break
                case 'August':
                    month = 'Aug.'
                    break
                case 'September':
                    month = 'Sept.'
                    break
                case 'October':
                    month = 'Oct.'
                    break
                case 'November':
                    month = 'Nov.'
                    break
                case 'December':
                    month = 'Dec.'
                    break
                default:
                    break
            }
            let day = broadcastDate.toLocaleString('en-us', { day: 'numeric' })
            let year = broadcastDate.toLocaleString('en-us', { year: 'numeric' })
            let time = broadcastDate.toLocaleString('en-us', { hour: 'numeric', minute: '2-digit' }).replace('AM', 'a.m.').replace('PM','p.m.').replace(':00','')
            return weekday + ', ' + month + ' ' + day + ', ' + year + ' at ' + time
        },
        linkText(broadcast) {
            return broadcast.section_title + ': ' + broadcast.title + ', ' + this.longDate(broadcast.date)
        },
        scrollBehavior() {
            if (typeof(this.$refs[this.row.title]) !== 'undefined' && this.$refs[this.row.title].clientWidth > 0) {
                if (this.slidesPerPage > this.row.response.item_count) {
                    this.$refs[this.row.title+'prev'].style.visibility = 'hidden'
                    this.$refs[this.row.title+'next'].style.visibility = 'hidden'
                } else {
                    if (this.$refs[this.row.title].scrollLeft == 0) {
                        this.$refs[this.row.title+'prev'].style.visibility = 'hidden'
                    } else {
                        this.$refs[this.row.title+'prev'].style.visibility = 'visible'
                    }
                    if (this.$refs[this.row.title].scrollLeft + this.$refs[this.row.title].clientWidth + 1 >= this.$refs[this.row.title].scrollWidth) {
                        this.$refs[this.row.title+'next'].style.visibility = 'hidden'
                        this.updateBroadcasts()
                    } else {
                        this.$refs[this.row.title+'next'].style.visibility = 'visible'
                    }
                }
            }
        },
        scrollLeft() {
            this.$refs[this.row.title].scroll({ left: this.$refs[this.row.title].scrollLeft - 312, behavior: 'smooth' })
        },
        scrollRight() {
            this.$refs[this.row.title].scroll({ left: this.$refs[this.row.title].scrollLeft + 312, behavior: 'smooth' })
        },
        selectBroadcast(broadcast) {
            if (!this.dragged) {
                let swiperRow = document.getElementById('swiper-content-'+this.row.title)
                if (swiperRow.hasChildNodes()) {
                    let swiperSlides = swiperRow.childNodes
                    for (let i = 0; i < swiperSlides.length; ++i) {
                        if ('true' == swiperSlides[i].getAttribute('aria-pressed')) {
                            swiperSlides[i].setAttribute('aria-pressed', false)
                            break
                        }
                    }
                }
                let swiperSlide = document.getElementById(broadcast.id)
                if (swiperSlide) {
                    swiperSlide.setAttribute('aria-pressed', true)
                }
                if (this.searchOmni && 'search' == this.type) {
                    this.$store.commit('SET_SEARCH', false)
                }
                if (this.forceSelect) {
                    this.$store.commit('SET_FORCESELECT', false)
                }
                this.$store.commit('SET_BROADCAST', broadcast)
                this.$store.commit('SET_URL')
                window.scroll({ top: this.position.y, left: this.position.x, behavior: 'smooth' })
            }
        },
        slideImage(broadcast_image) {
            return {
                'position': 'relative',
                'height': this.imageHeight+'px',
                'width': '100%',
                'background': 'url('+broadcast_image+') no-repeat center / cover'
            }
        },
        time(broadcast_date) {
            let broadcastDate = new Date(broadcast_date)
            let timeOptions = {
                hour: 'numeric',
                minute: '2-digit'
            }
            return broadcastDate.toLocaleString('en-us', timeOptions).replace(':00','')
        },
        truncateString(str, num) {
            if (str.length <= num) {
                return str
            }
            return str.slice(0, num) + '...'
        },
        updateBroadcasts() {
            if (!this.updating && this.row.broadcastSearchParams.page < this.row.response.num_pages)
            {
                this.updating = true
                this.$store.dispatch('GET_BROADCASTS', {type:this.type, site:this.site, index:this.layoutIndex, update:true})
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.scrollBehavior()
        })
    },
    watch: {
        queryUpdates() {
            this.updating = false
            this.$nextTick(() => {
                this.scrollBehavior()
            })
        },
        width() {
            if (this.mobile) {
                this.slidesPerPage = (this.width)/(312)
            } else {
                this.slidesPerPage = (this.width-24)/(312)
            }
            this.$nextTick(() => {
                this.scrollBehavior()
            })
        }
    }
}
</script>

<style>
.swiper-wrapper {
    width: 100%;
    overflow: hidden;
    position: relative;
}
.swiper-content {
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
    scrollbar-width: none;  /* Hide scrollbar for Firefox */
}
.swiper-content::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}
.swiper-slide {
    display: inline-block;
    cursor: pointer;
    width: 300px;
    text-align: left;
    margin-right: 12px;
    white-space: normal;
}
.swiper-slide:hover {
    box-shadow: 0 0 4px 3px var(--v-primary-lighten4);
}
.swiper-slide:focus {
    outline: none !important;
    box-shadow: 0 0 4px 3px var(--v-primary-lighten4);
}
.swiper-slide:active {
    box-shadow: 0 0 4px 3px var(--v-primary-lighten4);
}
.broadcast-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 6px;
    height: 40px;
}
.site-text {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 6px;
    color:rgba(255,255,255,0.8);
}
.section-text {
    font-size: 14px;
    line-height: 20px;
    color:rgba(255,255,255,0.6);
}
.swiper-button-prev, .swiper-button-next {
    z-index: 3;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 25px;
    background-color: rgba(0,0,0,0.3);
    background-position: center;
    cursor: pointer;
}
.swiper-button-prev {
    left: 0;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'rgba(255,255,255,0.7)'%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
}
.swiper-button-prev:hover {
    border: 1px solid white;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
}
.swiper-button-next {
    right: 0;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'rgba(255,255,255,0.7)'%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
}
.swiper-button-next:hover {
    border: 1px solid white;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
}
.results-padding {
    padding: 8px;
}
.screen-info-box {
    float: left;
    text-align: left;
    z-index: 2;
    display: flex;
    align-items: center;
    font-size: 10px;
    color: rgba(255,255,255,0.7);
    background-color: #101417;
    border-radius: 4px;
    margin: 8px 0 0 12px;
    padding: 6px 4px;
}
.bottom-icon {
    position: absolute;
    bottom: 8px;
}
.live-text {
    color:#fff;
    text-transform: uppercase;
    font-weight: bold;
}
.slide-section {
    font-weight: bold;
    padding: 8px;
}
</style>
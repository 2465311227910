import Vue from 'vue'
Vue.config.productionTip = false

import './vuetify'

import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()
Vue.use(smoothscroll)

import VueJsonp from 'vue-jsonp'
Vue.use(VueJsonp)

import Vuex from 'vuex'
Vue.use(Vuex)

import App from './App.vue'
import router from './router'
import store from './store'
App.router = router
App.store = store

import 'document-register-element/build/document-register-element'
import vueCustomElement from 'vue-custom-element'
Vue.use(vueCustomElement)
Vue.customElement('blueframe-app', App, {
    props: [
        'colorprimary',
        'colorsecondary',
        'domain',
        'excludebroadcasts',
        'excludesections',
        'excludesites',
        'forceselect',
        'fullsearch',
        'omni',
        'portalbg',
        'portalbroadcast',
        'portalconfig',
        'portalmode',
        'portalnav',
        'portaloffset',
        'portalsections',
        'portalsite',
        'portalsponsors',
        'portaltheme',
        'sharedsites',
        'siteids'
    ]
})

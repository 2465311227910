<template>
    <div class="links-nav">
        <div class="links-nav-dropdown">
            <div class="links-nav-hamburger" :style="hamburgerColor" id="hamburgerMenu" @click="toggleDropdown()" @keyup.enter.space="toggleDropdown()" tabindex="0" role="button" aria-pressed="false" aria-controls="externalNavLinks" aria-label="External Links">&#9776;</div>
            <div class="links-nav-content" id="externalNavLinks" aria-expanded="false">
                <a v-for="(link,index) in displayLinks" :key="index" :href="link.url" :aria-label="'External link to '+link.label" target="_blank" rel="noreferrer">
                    <v-icon v-if="link.icon" color="primary darken-1" small>{{link.icon}}</v-icon> {{link.label}}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'LinksRow',
    computed: {
        displayLinks() {
            let displayLinks = JSON.parse(JSON.stringify(this.links))
            for (let i = 0; i < displayLinks.length; ++i) {
                if (displayLinks[i].url.indexOf('//') === -1) {
                    displayLinks[i].url = '//' + displayLinks[i].url
                }
                if (displayLinks[i].url.indexOf('facebook') >= 0) {
                    displayLinks[i].icon = 'fo-facebook'
                } else if (displayLinks[i].url.indexOf('twitter') >= 0) {
                    displayLinks[i].icon = 'fo-twitter'
                } else if (displayLinks[i].url.indexOf('instagram') >= 0) {
                    displayLinks[i].icon = 'fo-instagram'
                } else if (displayLinks[i].url.indexOf('youtube') >= 0) {
                    displayLinks[i].icon = 'fo-youtube'
                } else {
                    displayLinks[i].icon = null
                }
            }
            return displayLinks
        },
        hamburgerColor() {
            if (this.dark) {
                return { 'color': 'white' }
            } else {
                return { 'color': 'black' }
            }
        },
        ...mapState([
            'dark',
            'links'
        ])
    },
    methods: {
        hideDropdown(event) {
            let externalNavLinks = document.getElementById('externalNavLinks')
            if (externalNavLinks.classList.contains('show') && !event.target.matches('.links-nav-hamburger')) {
                this.toggleDropdown()
            }
        },
        toggleDropdown() {
            let hamburgerMenu = document.getElementById('hamburgerMenu')
            let externalNavLinks = document.getElementById('externalNavLinks')
            if ('true' == externalNavLinks.getAttribute('aria-expanded')) {
                hamburgerMenu.setAttribute('aria-pressed', false)
                externalNavLinks.setAttribute('aria-expanded', false)
            } else {
                hamburgerMenu.setAttribute('aria-pressed', true)
                externalNavLinks.setAttribute('aria-expanded', true)
            }
            externalNavLinks.classList.toggle('show')
        }
    },
    created() {
        window.addEventListener('click', this.hideDropdown)
    },
    destroyed() {
        window.removeEventListener('click', this.hideDropdown)
    }
}
</script>

<style>
.links-nav {
    padding: 12px;
}
.links-nav::after {
    content:'';
    clear:both;
    display:table;
}
.links-nav-dropdown {
    float:right;
    position:relative;
    display:block;
}
.links-nav-hamburger {
    padding:6px;
    cursor:pointer;
    font-size:2em;
    line-height:inherit;
}
.links-nav-content {
    display:none;
    position:absolute;
    background-color:white;
    min-width:200px;
    overflow:auto;
    right:0;
    z-index:101;
    font-size:0.9em;
}
.links-nav-content a {
    color:black;
    padding:16px;
    text-decoration:none;
    display:block;
    border-width:0 0 1px 0;
    border-style:solid;
}
.links-nav-content a:hover {
    background-color:var(--v-primary-lighten5);
}
.show {
    display:block;
}
</style>

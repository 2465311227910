<template>
    <div class="layout-row">
        <v-container>
            <v-layout align-start justify-center>
                <div class="select-box">
                    <v-combobox v-model="selected" :items="slugs" placeholder="Select Config" ></v-combobox>
                </div>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ConfigRow',
    data() {
        return {
            selected: '',
            slugs: [
                'bft/5toLive',
                'bft/aeast',
                'bft/AlleghenyCollege',
                'bft/apex',
                'bft/apexsportssolo',
                'bft/BabeRuth',
                'bft/BAOSN',
                'bft/benedictine',
                'bft/bernewitness',
                'bft/beterrific',
                'bft/bft',
                'bft/bigsports',
                'bft/blueframelive',
                'bft/BlueFrameTech',
                'bft/cacc',
                'bft/CarterMedia',
                'bft/CarterMedia2',
                'bft/ccaa',
                'bft/Centennial',
                'bft/cfvideo',
                'bft/chatterboxsports',
                'bft/ChristtheKingMadisonville',
                'bft/ciaa',
                'bft/CityLinkTV',
                'bft/clclancers',
                'bft/conferencecarolinas',
                'bft/connorapp',
                'bft/cpsn',
                'bft/d2u',
                'bft/daltonstatecollege',
                'bft/dbubroadcast',
                'bft/dev',
                'bft/diamondclearmedia',
                'bft/dulutheastgreyhounds',
                'bft/ecc',
                'bft/fcbl',
                'bft/fccalbia',
                'bft/FirstChristianEddyville',
                'bft/fpcknoxville',
                'bft/fyntv',
                'bft/glvc',
                'bft/gostillman',
                'bft/graceland',
                'bft/GrandPark',
                'bft/GrandView',
                'bft/GVSU',
                'bft/HBCTV25',
                'bft/hoa',
                'bft/hocking',
                'bft/HoosierLandTV',
                'bft/ICCACSportsNetwork',
                'bft/ihsaatv',
                'bft/indianasrn',
                'bft/ion',
                'bft/iwlca',
                'bft/JacksonStateUniversity',
                'bft/jjsdisabled',
                'bft/jockjiveliveandarchived',
                'bft/JockJiveSportsARCHIVEDONLY',
                'bft/JockJiveSportsLIVEONLY',
                'bft/JourneyChurch',
                'bft/KBC',
                'bft/kbchoopspodcast',
                'bft/KBF',
                'bft/kjccc',
                'bft/LaceyComplex',
                'bft/LandmarkNetwork',
                'bft/littleeast',
                'bft/LittleLeague',
                'bft/lonestar',
                'bft/ManimalElite2020',
                'bft/martinmedia',
                'bft/mbspringmeets',
                'bft/mec',
                'bft/miaa',
                'bft/michianapromotions',
                'bft/MidState',
                'bft/morehouseathletics',
                'bft/MountMercy',
                'bft/mqhr',
                'bft/mshsaatv',
                'bft/Musco',
                'bft/ncac',
                'bft/nccaa',
                'bft/ncrha',
                'bft/nec',
                'bft/necbl',
                'bft/nescac',
                'bft/NEWMAC',
                'bft/njcaadisabled',
                'bft/njcaatvdiiimbb',
                'bft/njcaatvdiiimsoc',
                'bft/njcaatvdiiivb',
                'bft/njcaatvdiiiwbb',
                'bft/njcaatvdiiiwsoc',
                'bft/njcaatvdiimbb',
                'bft/njcaatvdiivb',
                'bft/njcaatvdiiwbb',
                'bft/njcaatvdimbb',
                'bft/njcaatvdimsoc',
                'bft/njcaatvdivb',
                'bft/njcaatvdiwbb',
                'bft/njcaatvdiwsoc',
                'bft/njcu',
                'bft/NSIC',
                'bft/nsnracing',
                'bft/nsnsn',
                'bft/NSNSNColleges',
                'bft/nsnsnHighSchools',
                'bft/nwac',
                'bft/odacsn',
                'bft/osn',
                'bft/packnetwork',
                'bft/PalmBeach',
                'bft/patriotbroadcastingnetwork',
                'bft/pbc',
                'bft/pennstate',
                'bft/playnorthstar',
                'bft/prep',
                'bft/prepca',
                'bft/Prepcasts',
                'bft/prepfl',
                'bft/prepga',
                'bft/prepia',
                'bft/prepil',
                'bft/prepin',
                'bft/prepks',
                'bft/prepky',
                'bft/prepmo',
                'bft/prepoh',
                'bft/prepok',
                'bft/preppa',
                'bft/preptn',
                'bft/preptx',
                'bft/prepva',
                'bft/prepwv',
                'bft/Production',
                'bft/rrsn',
                'bft/rrsnhomepage',
                'bft/scenicwest',
                'bft/shakopeehs',
                'bft/slipperyrock',
                'bft/southeasternfire',
                'bft/southernindianasports',
                'bft/southerniowafair',
                'bft/southlandconference',
                'bft/ssac',
                'bft/ssc',
                'bft/sstv',
                'bft/stevenson',
                'bft/stlukenicholasville',
                'bft/supporttest',
                'bft/SweatLex',
                'bft/tapps',
                'bft/Team1Sports',
                'bft/Test',
                'bft/TheSunConference',
                'bft/topseedtennislive',
                'bft/uva',
                'bft/varsitymedia',
                'bft/wac',
                'bft/williampenn',
                'bft/wjob',
                'bft/wjobbusiness',
                'bft/wjobcommunity',
                'bft/wjobentertainment',
                'bft/wjobgovernment',
                'bft/wjobhealth',
                'bft/wjobhighlights',
                'bft/wjobsports',
                'bft/wlsn',
                'bft/worshiplive',
                'mvp/eddyville',
                'mvp/Grapevine',
                'mvp/MCRF',
                'mvp/MuscoVision'
            ]
        }
    },
    computed: {
        ...mapState([
            'configParent',
        ])
    },
    watch: {
        selected() {
            this.$store.dispatch('CHANGE_CONFIG', this.configParent + this.selected + '/config.json')
        }
    }
}
</script>

<style>
.select-box {
    width:300px;
}
</style>
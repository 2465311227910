<template>
    <div class="breadcrumb-nav mobile-padding" :style="'color:'+navColor+';'">
        <span v-if="null !== selectedSite" class="breadcrumb-flex">
            <span class="breadcrumb-link" @click="noSite" @keyup.enter.space="noSite" tabindex="0" role="button" aria-pressed="false" :aria-label="'Go back to '+config.title">{{config.title}}</span>
            <span class="breadcrumb-separator"><v-icon size="18px" :color="navColor">fo-right</v-icon></span>
            <span class="breadcrumb-link">{{sites[selectedSite].title}}</span>
        </span>
        <span v-else class="breadcrumb-flex">
            <span class="breadcrumb-link">{{config.title}}</span>
        </span>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'BreadCrumbRow',
    computed: {
        navColor() {
            if (this.dark) {
                return 'rgba(255,255,255,0.65)'
            } else {
                return 'rgba(0,0,0,0.65)'
            }
        },
        ...mapState([
            'config',
            'dark',
            'portalNavigation',
            'selectedSite',
            'sites'
        ])
    },
    methods: {
        noSite() {
            let query = JSON.parse(JSON.stringify(this.$route.query))
            if (this.portalNavigation && typeof(query.S) === 'undefined') {
                let pathname = location.pathname
                pathname = pathname.substring(0, pathname.lastIndexOf('/'))
                pathname = pathname.substring(0, pathname.lastIndexOf('/'))
                location.href = location.protocol + '//' + location.hostname + pathname
            } else {
                this.$store.dispatch('NO_SITE', null)
            }
        }
    }
}
</script>

<style>
.breadcrumb-nav {
    font-size: 12px;
    line-height: 17px;
}
.breadcrumb-flex {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}
.breadcrumb-link {
    text-decoration: none;
    cursor: pointer;
}
.breadcrumb-separator {
    margin: 0 2px;
}
</style>

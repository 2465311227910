<template>
    <vue-calendar :theme="{color:'primary', isDark:dark}" v-model="dates" mode="range" :popover="popover">
        <v-text-field
            slot-scope="{ inputValue }"
            color="secondary lighten-3"
            :value="inputValue"
            @change="parseDateInput($event)"
            @click:clear="resetDates"
            label="Search by date"
            clearable
        ></v-text-field>
    </vue-calendar>
</template>

<script>
import { mapState } from 'vuex'
import { DatePicker} from 'v-calendar'

export default {
    name: 'SearchDate',
    components: {
        'vue-calendar': DatePicker
    },
    props: {
        searchDates: {
            type: Object,
            required: false
        },
    },
    data() {
        return {
            dates: { start:null, end:null },
            popover: {
                visibility: 'focus'
            }
        }
    },
    computed: {
        ...mapState([
            'dark',
        ])
    },
    methods: {
        parseDateInput(datesString) {
            let dates = { start:null, end:null }
            datesString = datesString.replace(' ','')
            if (datesString.length > 0) {
                if (datesString.includes('-')) {
                    let dateArray = datesString.split('-')
                    if (dateArray.length > 0) {
                        dates.start = new Date(dateArray[0])
                        dates.end = new Date(dateArray[0])
                    }
                    if (dateArray.length > 1) {
                        dates.end = new Date(dateArray[1])
                    }
                } else {
                    dates.start = new Date(datesString)
                    dates.end = new Date(datesString)
                }
            } else {
                dates = null
            }
            this.dates = dates
        },
        resetDates() {
            this.dates = null
        }
    },
    watch: {
        dates() {
            if (this.dates === null) {
                if (this.searchDates !== null) {
                    this.$emit('search-dates', this.dates)
                }
            } else {
                if (this.searchDates === null) {
                    this.$emit('search-dates', this.dates)
                } else if (this.dates.start !== this.searchDates.start || this.dates.end !== this.searchDates.end) {
                    this.$emit('search-dates', this.dates)
                }
            }
        },
        searchDates() {
            this.dates = this.searchDates
        },
    }
}
</script>

<style>
.vc-border-gray-700 {
    border-color:#424242 !important;
}
.vc-bg-gray-900 {
    background-color:#424242 !important;
}
.vc-bg-primary-100 {
    background-color:var(--v-primary-lighten5);
}
.vc-bg-primary-200{
    background-color:var(--v-primary-lighten4);
}
.vc-bg-primary-300{
    background-color:var(--v-primary-lighten3);
}
.vc-bg-primary-400{
    background-color:var(--v-primary-lighten2);
}
.vc-bg-primary-500{
    background-color:var(--v-primary-lighten1);
}
.vc-bg-primary-600{
    background-color:var(--v-primary-base);
}
.vc-bg-primary-700{
    background-color:var(--v-primary-darken1);
}
.vc-bg-primary-800{
    background-color:var(--v-primary-darken2);
}
.vc-bg-primary-900{
    background-color:var(--v-primary-darken3);
}
.vc-text-primary-100{
    color:var(--v-primary-lighten5);
}
.vc-text-primary-200{
    color:var(--v-primary-lighten4);
}
.vc-text-primary-300{
    color:var(--v-primary-lighten3);
}
.vc-text-primary-400{
    color:var(--v-primary-lighten2);
}
.vc-text-primary-500{
    color:var(--v-primary-lighten1);
}
.vc-text-primary-600{
    color:var(--v-primary-base);
}
.vc-text-primary-700{
    color:var(--v-primary-darken1);
}
.vc-text-primary-800{
    color:var(--v-primary-darken2);
}
.vc-text-primary-900{
    color:var(--v-primary-darken3);
}
.vc-border-primary-100{
    border-color:var(--v-primary-lighten5);
}
.vc-border-primary-200{
    border-color:var(--v-primary-lighten4);
}
.vc-border-primary-300{
    border-color:var(--v-primary-lighten3);
}
.vc-border-primary-400{
    border-color:var(--v-primary-lighten2);
}
.vc-border-primary-500{
    border-color:var(--v-primary-lighten1);
}
.vc-border-primary-600{
    border-color:var(--v-primary-base);
}
.vc-border-primary-700{
    border-color:var(--v-primary-darken1);
}
.vc-border-primary-800{
    border-color:var(--v-primary-darken2);
}
.vc-border-primary-900{
    border-color:var(--v-primary-darken3);
}
</style>
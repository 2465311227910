<template>
    <div class="layout-row">
        <div class="mobile-padding row-title" role="heading" aria-level="2">{{ row.title }}</div>
        <div class="grid-layout">
            <div v-for="(sponsor,index) in row.sponsorsParams.sponsors" :key="index">
                <a :href="sponsor.webUrl" target="_blank" rel="noreferrer" class="cell-link" tabindex="0" :title="sponsor.title" :aria-label="sponsor.title">
                    <div class="sponsors-bg" :style="{ 'background-image': 'url('+getImage(sponsor)+')' }"></div>
                </a>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'SponsorsRow',
    props: {
        row: {
            type: Object,
            required: true
        },
    },
    methods: {
        getImage(sponsor) {
            if (typeof(sponsor.thumbnailMediumUrl) !== 'undefined') {
                return sponsor.thumbnailMediumUrl
            } else if (typeof(sponsor.thumbnailLargeUrl) !== 'undefined') {
                return sponsor.thumbnailLargeUrl
            } else if (typeof(sponsor.thumbnailSmallUrl) !== 'undefined') {
                return sponsor.thumbnailSmallUrl
            } else {
                return ''
            }
        }
    }
}
</script>

<style>
.sponsors-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.sponsors-bg:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  padding-bottom: calc(2/3 * 100%);
}
</style>

<template>
    <div class="search-box">
        <div class="search-item-medium" v-if="filters.showSitesFilter && (null === selectedSite)">
            <SearchSite :searchSiteTitle="searchSiteTitle" @search-site="searchBySite" />
        </div>
        <div class="search-item-medium" v-if="filters.showSectionsFilter && (sectionsString.length > 0)">
            <SearchSection :searchSectionTitle="searchSectionTitle" @search-section="searchBySection" />
        </div>
        <div class="search-item-medium" v-if="filters.showDateFilter">
            <SearchDate :searchDates="searchDates" @search-dates="searchByDates" />
        </div>
        <div class="search-item-large" v-if="filters.showTitleFilter">
            <SearchTitle :searchTitleName="searchTitleName" @search-title="searchByTitle" />
        </div>
        <div class="search-item-small">
            <v-btn flat color="secondary" @click="resetSearch" title="Clear All" aria-label="Clear All">Clear all</v-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import SearchSite from './SearchSite.vue'
import SearchSection from './SearchSection.vue'
import SearchTitle from './SearchTitle.vue'
import SearchDate from './SearchDate.vue'

const defaultSearch = () => {
    return {
        searchSite: false,
        searchSiteId: null,
        searchSiteTitle: '',
        searchTitle: false,
        searchSection: false,
        searchSectionId: null,
        searchSectionTitle: '',
        searchString: '',
        searchDate: false,
        searchDates: null,
        searchTitleName: ''
    }
}

export default {
    name: 'SearchControl',
    components: {
        SearchSite,
        SearchSection,
        SearchTitle,
        SearchDate
    },
    data() {
        return defaultSearch()
    },
    computed: {
        ...mapState([
            'filters',
            'labels',
            'searchIndex',
            'searchQueries',
            'sectionArray',
            'sectionsString',
            'selectedSite',
            'siteArray',
            'sitesString'
        ])
    },
    methods: {
        resetSearch() {
            this.$store.commit('SET_SEARCH', false)
            this.$store.commit('SET_SEARCHREADY', false)
            this.$store.commit('SET_SEARCHINDEX', null)
            Object.assign(this.$data, defaultSearch())
        },
        buildSearch() {
            let newQuery = {}
            newQuery.response = { broadcasts: [] }
            newQuery.updates = 0
            newQuery.title = this.searchString
            newQuery.broadcastSearchParams = {}
            newQuery.broadcastSearchParams.include_deletions = 0
            newQuery.broadcastSearchParams.page = 1
            newQuery.broadcastSearchParams.per_page = 25
            // site_id always required
            if (null !== this.selectedSite) {
                newQuery.broadcastSearchParams.site_id = this.selectedSite.toString()
            } else if (this.searchSite) {
                newQuery.broadcastSearchParams.site_id = this.searchSiteId
            } else {
                newQuery.broadcastSearchParams.site_id = this.sitesString
            }
            // Section_id required to omit test/invalid sections
            if (this.searchSection) {
                newQuery.broadcastSearchParams.section_id = this.searchSectionId
            } else if (this.sectionsString.length > 0) {
                newQuery.broadcastSearchParams.section_id = this.sectionsString
            }
            // Title not required
            if (this.searchTitle) {
                newQuery.broadcastSearchParams.title = this.searchTitleName
            }
            // Date not required
            if (this.searchDate) {
                let startDate = new Date(this.searchDates.start.getTime())
                let endDate = new Date(this.searchDates.end.getTime())
                if (startDate > endDate) {
                    let tempDate = new Date(endDate.getTime())
                    endDate = new Date(startDate.getTime())
                    startDate = new Date(tempDate.getTime())
                }
                startDate.setHours(0,0,0,0)
                endDate.setHours(0,0,0,0)
                endDate.setDate(endDate.getDate() + 1)
                newQuery.broadcastSearchParams.after = startDate.toUTCString()
                newQuery.broadcastSearchParams.before = endDate.toUTCString()
            }
            newQuery.broadcastSearchParams.sort_by = 'date'
            newQuery.broadcastSearchParams.sort_dir = 'desc'
            this.$store.commit('ADD_SEARCHQUERY', newQuery)
            this.$store.dispatch('GET_BROADCASTS', {type:'search', index:this.searchIndex, update:false})
        },
        checkSearch() {
            this.$store.commit('SET_SEARCHREADY', false)
            if (!this.searchDate && !this.searchSection && !this.searchSite && !this.searchTitle) {
                this.$store.commit('SET_SEARCH', false)
            } else {
                let searchTitle = this.labels.broadcastLabelPlural
                if (this.searchSite) {
                    searchTitle += ' by ' + this.searchSiteTitle
                }
                if (this.searchSection) {
                    searchTitle += ' featuring ' + this.searchSectionTitle
                }
                if (this.searchTitle) {
                    searchTitle += ' named ' + this.searchTitleName
                }
                if (this.searchDate) {
                    let startDate = new Date(this.searchDates.start.getTime())
                    let endDate = new Date(this.searchDates.end.getTime())
                    if (startDate > endDate) {
                        let tempDate = new Date(endDate.getTime())
                        endDate = new Date(startDate.getTime())
                        startDate = new Date(tempDate.getTime())
                    }
                    let start = ( startDate.getMonth() + 1 ) + '/' + startDate.getDate() + '/' + startDate.getFullYear()
                    let end = ( endDate.getMonth() + 1 ) + '/' + endDate.getDate() + '/' + endDate.getFullYear()
                    searchTitle += ' between ' + start + ' and ' + end
                }
                let newSearch = true
                this.searchString = searchTitle
                for (let i = 0; i < this.searchQueries.length; ++i) {
                    if (this.searchString === this.searchQueries[i].title) {
                        newSearch = false
                        this.$store.commit('SET_SEARCHINDEX', i)
                        this.$store.commit('SET_SEARCHREADY', true)
                    }
                }
                if (newSearch) {
                    this.$store.commit('SET_SEARCHINDEX', this.searchQueries.length)
                    this.buildSearch()
                }
                this.$store.commit('SET_SEARCH', true)
            }
        },
        searchByDates(dates) {
            this.searchDates = dates
            if (null === this.searchDates) {
                this.searchDate = false
            } else {
                this.searchDate = true
            }
            this.checkSearch()
        },
        searchBySection(index) {
            if (null === index) {
                this.searchSection = false
                this.searchSectionId = null
                this.searchSectionTitle = ''
            } else {
                this.searchSection = true
                this.searchSectionId = this.sectionArray[index].id.toString()
                this.searchSectionTitle = this.sectionArray[index].title
            }
            this.checkSearch()
        },
        searchBySite(index) {
            if (null === index) {
                this.searchSite = false
                this.searchSiteId = null
                this.searchSiteTitle = ''
            } else {
                this.searchSite = true
                this.searchSiteId = this.siteArray[index].id.toString()
                this.searchSiteTitle = this.siteArray[index].title
            }
            this.checkSearch()
        },
        searchByTitle(title) {
            this.searchTitleName = title
            if ('' === title) {
                this.searchTitle = false
            } else {
                this.searchTitle = true
            }
            this.checkSearch()
        }
    }
}
</script>

<style>
.search-box {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding: 12px 0 12px 12px;
}
.search-item-small {
    flex-grow: 0;
    margin-right: 12px;
}
.search-item-medium {
    flex-grow: 1;
    margin-right: 12px;
}
.search-item-large {
    flex-grow: 2;
    min-width: 250px;
    margin-right: 12px;
}
</style>
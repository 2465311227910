<template>
    <v-combobox color="secondary lighten-3" v-model="selected" :items="siteTitles" :label="'Search by ' + labels.siteLabel.toLowerCase()" clearable></v-combobox>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'SearchSite',
    props: {
        searchSiteTitle: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            selected: ''
        }
    },
    computed: {
        siteTitles() {
            let siteTitles = []
            for (let i = 0; i < this.siteArray.length; ++i) {
                siteTitles.push(this.siteArray[i].title)
            }
            return siteTitles
        },
        ...mapState([
            'labels',
            'siteArray'
        ])
    },
    watch: {
        selected() {
            if (typeof(this.selected) === 'undefined') {
                this.$emit('search-site', null)
            } else {
                for (let i = 0; i < this.siteArray.length; ++i) {
                    if (this.selected === this.siteArray[i].title) {
                        this.$emit('search-site', i)
                        break
                    }
                }
            }
        },
        searchSiteTitle() {
            if (this.selected !== this.searchSiteTitle) {
                this.selected = this.searchSiteTitle
            }
        }
    }
}
</script>

<template>
    <div v-if="displayApps.length > 0" class="layout-row">
        <div class="mobile-padding row-title" role="heading" aria-level="2">{{ row.title }}</div>
        <div class="grid-layout">
            <div v-for="(app,index) in displayApps" :key="index">
                <div class="cell-link" @click="selectApp(app)" @keyup.enter.space="selectApp(app)" tabindex="0" role="button" aria-pressed="false" :title="'Select app: '+app.title" :aria-label="'Select app: '+app.title">
                    <div class="app-bg" :style="appStyle(app)">
                    </div>
                    <div class="cell-title primary-bg"><div class="truncate" v-html="app.title"></div></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'AppsRow',
    props: {
        row: {
            type: Object,
            required: true
        },
        search: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            appSearch: ''
        }
    },
    computed: {
        displayApps() {
            let displayApps = []
            for (let i = 0; i < this.row.embeddedAppParams.embeddedApps.length; ++i) {
                if (typeof(this.row.embeddedAppParams.embeddedApps[i].isActive) == 'undefined' || this.row.embeddedAppParams.embeddedApps[i].isActive == true) {
                    if (this.search) {
                        if ('' != this.appSearch) {
                            let titleCompare = this.row.embeddedAppParams.embeddedApps[i].title.toLowerCase()
                            if (titleCompare.search(this.appSearch) !== -1) {
                                displayApps.push(this.row.embeddedAppParams.embeddedApps[i])
                            }
                        }
                    } else {
                        displayApps.push(this.row.embeddedAppParams.embeddedApps[i])
                    }
                }
            }
            return displayApps
        },
        rowType() {
            return this.row.title.split(' ').pop()
        },
        ...mapState([
            'omnisearch',
            'position'
        ])
    },
    methods: {
        appStyle(app) {
            if (typeof(app.thumbnailMediumUrl) !== 'undefined') {
                return { 'background-image': 'url('+app.thumbnailMediumUrl+')' }
            } else if (typeof(app.thumbnailLargeUrl) !== 'undefined') {
                return { 'background-image': 'url('+app.thumbnailLargeUrl+')' }
            } else if (typeof(app.thumbnailSmallUrl) !== 'undefined') {
                return { 'background-image': 'url('+app.thumbnailSmallUrl+')' }
            } else {
                return ''
            }
        },
        selectApp(app) {
            if (typeof(app.webUrl) !== 'undefined') {
                if (typeof(app.newWindow) !== 'undefined') {
                    if (app.newWindow) {
                        window.open(app.webUrl, '_blank')
                    } else {
                        window.open(app.webUrl, '_self')
                    }
                } else {
                    window.open(app.webUrl, '_self')
                }
            } else {
                window.scroll({ top: this.position.y, left: this.position.x, behavior: 'smooth' })
                this.$store.dispatch('CHANGE_CONFIG', app.srcUrl)
            }
        }
    },
    watch: {
        omnisearch() {
            this.appSearch = this.omnisearch.toLowerCase()
        }
    }
}
</script>

<style>
.app-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
}
.app-bg:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  padding-bottom: calc(9/16 * 100%);
}
</style>

<template>
    <div class="layout-row">
        <div class="mobile-padding row-title" role="heading" aria-level="2">{{ row.title }}</div>
        <div class="grid-layout">
            <div v-for="(schedule,index) in row.schedules" :key="index">
                <div class="cell-link" @click="selectSchedule(schedule.id)" @keyup.enter.space="selectSchedule(schedule.id)" tabindex="0" role="button" aria-pressed="false" :title="'Select schedule: '+schedule.title" :aria-label="'Select schedule: '+schedule.title">
                    <div class="schedule-bg" :style="{ 'background-image': 'url('+getImage(schedule)+')' }"></div>
                    <div class="cell-title primary-bg"><div class="truncate" v-html="schedule.title"></div></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SchedulesRow',
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    methods: {
        getImage(schedule) {
            if (typeof(schedule.medium_image) !== 'undefined') {
                return schedule.medium_image
            } else if (typeof(schedule.large_image) !== 'undefined') {
                return schedule.large_image
            } else if (typeof(schedule.small_image) !== 'undefined') {
                return schedule.small_image
            } else {
                return ''
            }
        },
        selectSchedule(id) {
            this.$store.dispatch('GET_VSS_BROADCAST', id)
        }
    }
}
</script>

<style>
.schedule-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.schedule-bg:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  padding-bottom: calc(2/3 * 100%);
}
</style>

<template>
    <div id="blueframe-player" ref="BlueFramePlayer">
        <div v-if="broadcastReady">
            <v-snackbar color="success" v-model="alert" :timeout="3000" top multi-line role="alert" @click="closeAlert" aria-label="Close alert" aria-live="assertive">
                Link copied to clipboard! &nbsp;<v-icon>fo-check-circle</v-icon>
            </v-snackbar>

            <div class="player-flex">
                <div class="player-info" :style="infoStyle" aria-live="polite">
                    <div class="section-title" :style="'color:'+playerColor+';'" role="heading" aria-level="1" v-html="broadcast.section_title"></div>
                    <div class="player-title" role="heading" aria-level="2" v-html="broadcast.title"></div>
                    <div class="player-text" :style="'color:'+playerColor+';'" v-html="date(broadcast)"></div>
                    <div v-if="parsedDescription" class="player-text" :style="'color:'+playerColor+';'" v-html="parsedDescription"></div>
                    <div class="player-buttons">
                        <v-btn flat class="primary-bg player-button share-button" @click="openCloseShareLinks" title="Share broadcast" aria-label="Share broadcast">
                            Share &nbsp;<v-icon>fo-share</v-icon>
                        </v-btn>
                        <v-btn v-if="canDownload" flat class="primary-bg player-button" @click="downloadLink" title="Download broadcast" aria-label="Download broadcast">
                            Download &nbsp;<v-icon>fo-download</v-icon>
                        </v-btn>
                    </div>
                </div>
                <div class="player-grow">
                    <div class="mobile-padding" :style="growStyle">
                        <div class="section-title" :style="'color:'+playerColor+';'" role="heading" aria-level="1" v-html="broadcast.section_title"></div>
                        <div class="player-title" role="heading" aria-level="2" v-html="broadcast.title"></div>
                    </div>
                    <PlayerVideo :embed="embed_code" :height="height" :title="broadcast.title" />
                    <div class="mobile-padding" :style="growStyle">
                        <div class="player-buttons button-flex">
                            <v-btn flat class="primary-bg mobile-button" @click="openCloseAbout" title="About broadcast" aria-label="About broadcast">
                                About &nbsp;<v-icon>fo-info</v-icon>
                            </v-btn>
                            <v-btn flat class="primary-bg mobile-button share-button" @click="openCloseShareLinks" title="Share broadcast" aria-label="Share broadcast">
                                Share &nbsp;<v-icon>fo-share</v-icon>
                            </v-btn>
                            <v-btn v-if="canDownload" flat class="primary-bg mobile-button" @click="downloadLink" title="Download broadcast" aria-label="Download broadcast">
                                Download &nbsp;<v-icon>fo-download</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="hasStats">
                <PlayerStats :embed="stats_link" :height="height" :title="broadcast.title" />
            </div>

            <v-dialog v-model="shareLinks" width="300">
                <v-card :class="cardClass">
                    <v-card-title primary-title class="pb-2">
                        <div class="pop-up-title" role="heading" aria-level="3">Share broadcast</div>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="openCloseShareLinks" title="Close" aria-label="Close">
                            <v-icon>fo-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container fluid pa-0>
                            <v-layout align-center justify-space-around wrap fill-height>
                                <v-btn small icon @click="shareSocial('email')" title="Email" aria-label="Share via email">
                                    <v-icon>fo-email</v-icon>
                                </v-btn>
                                <v-btn small icon @click="shareSocial('facebook')" title="Facebook" aria-label="Share to Facebook">
                                    <v-icon>fo-facebook</v-icon>
                                </v-btn>
                                <v-btn small icon @click="shareSocial('twitter')" title="Twitter" aria-label="Share to Twitter">
                                    <v-icon>fo-twitter</v-icon>
                                </v-btn>
                                <v-btn small icon @click="shareSocial('reddit')" title="Reddit" aria-label="Share to Reddit">
                                    <v-icon>fo-reddit</v-icon>
                                </v-btn>
                                <v-btn small icon @click="copyLink" title="Copy" aria-label="Copy link to clipboard">
                                    <v-icon>fo-copy</v-icon>
                                </v-btn>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="about" width="300">
                <v-card :class="cardClass" aria-live="polite">
                    <v-card-title primary-title class="pb-2">
                        <div class="pop-up-title" role="heading" aria-level="3" v-html="broadcast.section_title"></div>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="openCloseAbout" title="Close" aria-label="Close">
                            <v-icon>fo-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <div class="player-title" role="heading" aria-level="4" v-html="broadcast.title"></div>
                        <div class="player-text" :style="'color:'+playerColor+';'" v-html="date(broadcast)"></div>
                        <div v-if="parsedDescription" class="player-text" :style="'color:'+playerColor+';'" v-html="parsedDescription"></div>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <div v-else-if="broadcastEmpty" class="player-flex" style="justify-content:flex-end;">
            <div v-if="selectedSite || hasBroadcasts" :style="noBroadcasts" aria-live="polite">
                <p style="color:white;margin:0;">No broadcasts found.</p>
            </div>
        </div>
        <div v-else class="player-preview">
            <v-progress-circular color="primary darken-2" :size="100" :width="5" indeterminate></v-progress-circular>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import PlayerVideo from './Player/PlayerVideo.vue'
import PlayerStats from './Player/PlayerStats.vue'

export default {
    name: 'PlayerRow',
    components: {
        PlayerVideo,
        PlayerStats
    },
    data() {
        return {
            about: false,
            alert: false,
            share_link: '',
            shareLinks: false
        }
    },
    computed: {
        canDownload() {
            if ('Archived' === this.broadcast.status && null !== this.broadcast.download_url) {
                return true
            } else {
                return false
            }
        },
        cardClass() {
            if (this.dark) {
                return 'primary-bg'
            } else {
                return ''
            }
        },
        embed_code() {
            let src = 'https://' + this.config.vCloud.domain + '/broadcast/embed/' + this.broadcast.id
            if (typeof(this.broadcast.autoplay) !== 'undefined' && this.broadcast.autoplay) {
                src += '?autoplay=1'
            }
            return  src
        },
        growStyle() {
            if (this.width < 888) {
                return {
                    'display': 'block'
                }
            } else {
                return {
                    'display': 'none'
                }
            }
        },
        height() {
            if (this.width < 888) {
                return this.width / 1.78
            } else {
                return (this.width * 0.7) / 1.78
            }
        },
        infoStyle() {
            if (this.width < 888) {
                return {
                    'display': 'none'
                }
            } else {
                return {
                    'width': (this.width * 0.3)+'px'
                }
            }
        },
        noBroadcasts() {
            return {
                'display': 'flex',
                'justify-content': 'center',
                'align-items': 'center',
                'background-color': '#2a2a2a',
                'height': (this.height)+'px',
                'width': (this.height*1.78)+'px'
            }
        },
        parsedDescription() {
            let description = ''

            if (this.broadcast.description !== null) {
                description = this.broadcast.description
            }

            if (description.length > 0) {
                // sanitize our old-formatted descriptions
                description = description.replace(/<[^>]*>?/gm, '');

                // turn urls into links
                description = description.replace(/(https?:\/\/[^\s]+)/g, function(url) {
                    return '<a class="simple-endpoint" href="' + url + '" rel="nofollow" target="_blank">' + url + '</a>';
                })

                // format individual lines
                let descriptionLines = description.split('\r\n')
                description = ''
                for (let i = 0; i < descriptionLines.length; ++i) {
                    description += '<p>'+descriptionLines[i]+'</p>'
                }
            }

            return description
        },
        playerColor() {
            if (this.dark) {
                return 'rgba(255,255,255,0.8)'
            } else {
                return 'rgba(0,0,0,0.8)'
            }
        },
        stats_link() {
            return this.broadcast.live_stats_embed.split("src='")[1].slice(0, -3)
        },
        ...mapState([
            'broadcast',
            'broadcastEmpty',
            'broadcastReady',
            'config',
            'dark',
            'hasBroadcasts',
            'hasStats',
            'selectedSite',
            'width'
        ])
    },
    methods: {
        closeAlert() {
            this.alert = false
        },
        copyLink() {
            this.updateShareLink()
            let copyElement = document.createElement('input')
            copyElement.value = this.share_link
            this.$refs.BlueFramePlayer.appendChild(copyElement)
            copyElement.select()
            copyElement.focus()
            document.execCommand('copy')
            copyElement.blur()
            this.$refs.BlueFramePlayer.removeChild(copyElement)
            if (this.shareLinks) {
                this.openCloseShareLinks()
            }
            this.alert = true
            setTimeout(this.closeAlert, 2000)
        },
        date(broadcast) {
            let broadcastDate = new Date(broadcast.date)
            let weekday = broadcastDate.toLocaleString('en-us', { weekday: 'long' })
            let month = broadcastDate.toLocaleString('en-us', { month: 'long' })
            switch (month) {
                case 'January':
                    month = 'Jan.'
                    break
                case 'February':
                    month = 'Feb.'
                    break
                case 'August':
                    month = 'Aug.'
                    break
                case 'September':
                    month = 'Sept.'
                    break
                case 'October':
                    month = 'Oct.'
                    break
                case 'November':
                    month = 'Nov.'
                    break
                case 'December':
                    month = 'Dec.'
                    break
                default:
                    break
            }
            let day = broadcastDate.toLocaleString('en-us', { day: 'numeric' })
            let year = broadcastDate.toLocaleString('en-us', { year: 'numeric' })
            let time = broadcastDate.toLocaleString('en-us', { hour: 'numeric', minute: '2-digit' }).replace('AM', 'a.m.').replace('PM','p.m.').replace(':00','')
            return weekday + ', ' + month + ' ' + day + ', ' + year + ' at ' + time
        },
        downloadLink() {
            window.open(this.broadcast.download_url, 'downloadWindow', 'width='+((this.height*1.78)-12)+',height='+(this.height+12))
        },
        openCloseAbout() {
            this.about = !this.about
        },
        openCloseShareLinks() {
            this.shareLinks = !this.shareLinks
        },
        shareSocial(service) {
            this.updateShareLink()
            if (this.shareLinks) {
                this.openCloseShareLinks()
            }
            let url = ''
            switch(service) {
                case 'email':
                    url = 'mailto:?subject=I wanted you to see this video&body=Check out this video! ' + encodeURIComponent(this.share_link)
                    window.location.href = url
                    break
                case 'facebook':
                    url = 'https://www.facebook.com/sharer/sharer.php?u=' + this.share_link
                    window.open(url, '_blank')
                    break
                case 'twitter':
                    url = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(this.broadcast.title + ' ' + this.share_link)
                    window.open(url, '_blank')
                    break
                case 'reddit':
                    url = 'http://www.reddit.com/submit?url=' + encodeURIComponent(this.share_link)
                    window.open(url, '_blank')
                    break
            }
        },
        updateShareLink() {
            let url = window.location.href
            if (url.indexOf('?') === -1) {
                url = url + '?B=' + this.broadcast.id
            } else if (url.indexOf('B=') === -1) {
                url = url + '&B=' + this.broadcast.id
            }
            this.share_link = url
        }
    }
}
</script>

<style>
.player-flex {
    display: flex;
    flex-direction: row;
}
.player-info {
    display: flex;
    flex-direction: column;
    margin-right: 36px;
}
.player-grow {
    flex-grow: 1;
}
.section-title {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    margin-bottom: 18px;
}
.player-title {
    text-align: left;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 18px;
}
.player-text {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 18px;
}
.player-buttons {
    margin-top: 18px;
}
.player-button {
    margin: 0 18px 18px 0;
}
.mobile-button {
    font-size: 10px;
    padding: 0;
    margin: 3px;
}
@media (min-width: 576px) {
    .mobile-button {
        font-size: 13px;
        padding: 0 8px;
        margin: 6px 8px;
    }
}
.button-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.player-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 200px 0;
}
a.simple-endpoint {
    text-decoration: none;
    color: #5599FF;
}
</style>
<template>
    <div class="hudl-label">
        <a href="https://www.hudl.com" aria-label="External link to Hudl" target="_blank" rel="noreferrer">
            <img :src="imageUrl" alt="Powered By Hudl TV" width="100">
        </a>
        <div class="legal">
            <a target="_blank" href="https://www.hudl.com/privacy" :style="'color:'+linkColor">Privacy&nbsp;Policy</a> &nbsp;|&nbsp; <a target="_blank" href="https://www.hudl.com/terms" :style="'color:'+linkColor">Terms&nbsp;&amp;&nbsp;Conditions</a> &nbsp;|&nbsp; <a target="_blank" href="https://www.hudl.com/privacy/us-states#section2d" :style="'color:'+linkColor">Do&nbsp;Not&nbsp;Sell&nbsp;My&nbsp;Personal&nbsp;Information</a>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'HudlRow',
    computed: {
        imageUrl() {
            if (this.dark) {
                return 'https://web-app.blueframetech.com/img/hudltv_white.png'
            } else {
                return 'https://web-app.blueframetech.com/img/hudltv_black.png'
            }
        },
        linkColor() {
            if (this.dark) {
                return 'var(--v-primary-lighten5)'
            } else {
                return 'var(--v-primary-darken5)'
            }
        },
        ...mapState([
            'dark'
        ])
    },
}
</script>

<style>
.hudl-label {
    text-align:center;
    font-size:smaller;
    margin-top:40px;
    margin-bottom:60px;
}
@media (min-width: 576px) {
    .hudl-label {
        margin-bottom:20px;
    }
}
.legal {
	margin: 30px 0 30px 0;
}
.legal > a {
	text-decoration: none;
}
.legal > a:hover {
	opacity: 0.5;
}
</style>
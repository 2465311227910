<template>
    <div class="layout-row">
        <div class="mobile-padding row-title" role="heading" aria-level="2">{{row.title}}</div>
        <div class="grid-layout">
            <div v-for="(item,index) in row.items" :key="index">
                <div class="primary-bg cell-link" @click="item.link? goToLink(item.link) : null" @keyup.enter.space="item.link? goToLink(item.link) : null" tabindex="0" role="button" aria-pressed="false" :title="'Goto article: '+item.title" :aria-label="'Goto article: '+item.title">
                    <div class="news-bg" v-if="item.image" v-show="!mobile" :style="{ 'background': 'url('+item.image+') no-repeat center / cover' }"></div>
                    <div class="cell-title">
                        <div class="news-title">{{item.title}}</div>
                        <p class="news-info">
                            <small><b>{{date(item.pubDate)}}</b>: {{truncated(item.description)}} <b>MORE</b></small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'NewsRow',
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters([
            'mobile'
        ])
    },
    methods: {
        date(pubDate) {
            let dateParts = pubDate.split(' ')
            let dateString = ''
            for (let i = 0; i < 4; ++i) {
                dateString += dateParts[i] + ' '
            }
            return dateString.slice(0,-1)
        },
        goToLink(url) {
            window.open(url, '_blank')
        },
        truncated(content) {
            if (content.length > 300) {
                return content.substring(0, 300)+' ...'
            } else {
                return content+' ...'
            }
        }
    }
}
</script>

<style>
.news-bg:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  padding-bottom: calc(77/100 * 100%);
}
.news-title {
    font-weight: bold;
    line-height: 1.1;
    margin: 0 0 6px 0;
}
.news-info {
    line-height: 1;
}
</style>

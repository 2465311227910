<template>
    <div v-if="(selectSites.length > 0) || (!search && siteRow.allowEdit)" class="layout-row">
        <div class="site-flex">
            <div class="mobile-padding row-title" role="heading" aria-level="2">{{ siteRow.title }}</div>
            <v-spacer></v-spacer>
            <div v-if="!search && siteRow.allowEdit">
                <v-btn flat small class="primary-bg edit-button" @click="openCloseDialog" @keyup.enter.space="openCloseDialog" tabindex="0" role="button" aria-pressed="false" :title="'Edit '+siteRow.title" :aria-label="'Edit '+siteRow.title">
                    <v-icon small>fo-pencil</v-icon>&nbsp; Edit {{ siteRow.title }}
                </v-btn>
            </div>
        </div>
        <div class="grid-layout">
            <div v-for="(site,index) in displaySites" :key="index">
                <div class="cell-link" @click="selectSite(site.id)" @keyup.enter.space="selectSite(site.id)" tabindex="0" role="button" aria-pressed="false" :title="'Select site: '+site.title" :aria-label="'Select site: '+site.title">
                    <div class="site-bg" :style="{ 'background-image': 'url('+site.image+')' }"></div>
                    <div class="cell-title primary-bg"><div class="truncate" v-html="site.title"></div></div>
                </div>
            </div>
        </div>
        <div v-if="rowsNeeded > rowsHave || expanded" class="site-flex expand-div">
            <v-btn flat :color="buttonColor" @click="expandRows" @keyup.enter.space="expandRows" tabindex="0" role="button" aria-pressed="false" title="Show more" aria-label="Show more">
                <v-icon small>{{ expandIcon }}</v-icon>&nbsp; {{ expandText }}
            </v-btn>
        </div>
        <v-dialog v-model="dialog" scrollable max-width="400px">
            <v-card :class="cardClass">
                <v-card-title primary-title class="pb-2">
                    <div class="pop-up-title" role="heading" aria-level="3">Edit {{ siteRow.title }}</div>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialog" @keyup.enter.space="openCloseDialog" title="Close" aria-label="Close">
                        <v-icon>fo-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <p>Please select the {{ siteRow.title }} you want to follow.</p>
                    <v-checkbox class="pt-0 mt-0" v-model="selectAll" :label="selectAllTitle" @change="selectAllSites"></v-checkbox>
                    <div v-for="(site,index) in siteRow.sites" :key="index">
                        <v-checkbox class="pt-0 mt-0" v-if="site.default" disabled v-model="site.display" :label="site.title" @change="updateCookies(site.id, site.display)"></v-checkbox>
                        <v-checkbox class="pt-0 mt-0" v-else v-model="site.display" :label="site.title" @change="updateCookies(site.id, site.display)"></v-checkbox>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'SitesRow',
    props: {
        row: {
            type: Object,
            required: true
        },
        search: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialog: false,
            expanded: false,
            maxRows: 4,
            selectAll: false,
            siteSearch: '',
            siteRow: {
                title: '',
                allowEdit: false,
                sites: []
            }
        }
    },
    computed: {
        buttonColor() {
            if (this.dark) {
                return 'rgba(255,255,255,0.7)'
            } else {
                return 'rgba(0,0,0,0.7)'
            }
        },
        cardClass() {
            if (this.dark) {
                return 'primary-bg'
            } else {
                return ''
            }
        },
        displaySites() {
            let sites = []
            let maxLength = this.selectSites.length
            if (this.rowsNeeded > this.maxRows) {
                maxLength = this.maxRows * this.perRow
            }
            for (let i = 0; i < maxLength; ++i) {
                sites.push(this.selectSites[i])
            }
            return sites
        },
        expandIcon() {
            if (this.rowsNeeded > this.rowsHave) {
                return 'fo-down'
            } else {
                return 'fo-up'
            }
        },
        expandText() {
            if (this.rowsNeeded > this.rowsHave) {
                return 'Show more'
            } else {
                return 'Show less'
            }
        },
        perRow() {
            return Math.floor((this.width + 12) / 242) // 230px min per site + 12px space
        },
        rowsHave() {
            return Math.ceil(this.displaySites.length / this.perRow)
        },
        rowsNeeded() {
            return Math.ceil(this.selectSites.length / this.perRow)
        },
        rowType() {
            return this.siteRow.title.split(' ').pop()
        },
        selectAllTitle() {
            if (this.selectAll) {
                return 'SELECT NONE'
            } else {
                return 'SELECT ALL'
            }
        },
        selectSites() {
            let sites = []
            for (let i = 0; i < this.siteRow.sites.length; ++i) {
                if (this.search) {
                    if ('' !== this.siteSearch) {
                        let titleCompare = this.siteRow.sites[i].title.toLowerCase()
                        if (titleCompare.search(this.siteSearch) !== -1) {
                            sites.push(this.siteRow.sites[i])
                        }
                    }
                } else {
                    if (this.siteRow.sites[i].display) {
                        sites.push(this.siteRow.sites[i])
                    }
                }
            }
            return sites
        },
        ...mapState([
            'configLink',
            'dark',
            'omnisearch',
            'portalNavigation',
            'position',
            'sites',
            'width'
        ]),
        ...mapGetters([
            'mobile'
        ])
    },
    methods: {
        expandRows() {
            if (this.rowsNeeded > this.maxRows) {
                this.expanded = true
                if (this.mobile) {
                    this.maxRows += 16
                } else {
                    this.maxRows += 8
                }
            } else {
                this.expanded = false
                if (this.mobile) {
                    this.maxRows = 16
                } else {
                    this.maxRows = 4
                }
            }
        },
        openCloseDialog() {
            this.dialog = !this.dialog
        },
        selectAllSites() {
            for (let i = 0; i < this.siteRow.sites.length; ++i) {
                if (!this.siteRow.sites[i].default) {
                    this.siteRow.sites[i].display = this.selectAll
                    this.updateCookies(this.siteRow.sites[i].id, this.siteRow.sites[i].display)
                }
            }
        },
        selectSite(site) {
            if (this.portalNavigation) {
                let pathname = location.pathname
                if (pathname.slice(-1) != '/') {
                    pathname = pathname.substring(0, pathname.lastIndexOf('/')+1)
                }
                location.href = location.protocol + '//' + location.hostname + pathname + this.sites[site].slug
            } else {
                this.$store.dispatch('SELECT_SITE', { site_number: site, broadcast_number: null })
                window.scroll({ top: this.position.y, left: this.position.x, behavior: 'smooth' })
            }
        },
        updateCookies(site, display) {
            let deletionDate = ''
            if (display) {
                deletionDate = 'Thu, 01 Jan 1970 00:00:01 GMT'
            } else {
                let d = new Date()
                d.setTime(d.getTime() + (365*24*60*60*1000))
                deletionDate = d.toGMTString()
            }
            let cookiePrefix = 'hudl-web-app'
            if (this.configLink.length > 0) {
                cookiePrefix = this.configLink
            }
            document.cookie = cookiePrefix + ':' + site.toString() + '=hidden; SameSite=Lax; expires=' + deletionDate
        }
    },
    watch: {
        omnisearch() {
            this.siteSearch = this.omnisearch.toLowerCase()
        },
        selectSites() {
            if (this.selectSites.length === this.siteRow.sites.length) {
                this.selectAll = true
            }
        },
    },
    mounted() {
        Vue.set(this, 'siteRow', this.row);
        if (!this.search) {
            let noneSelected = true
            for (let i = 0; i < this.siteRow.sites.length; ++i) {
                if (!this.siteRow.sites[i].default && this.siteRow.sites[i].display) {
                    noneSelected = false
                }
            }
            if (noneSelected) {
                this.selectAll = true
                this.selectAllSites()
            }
        }
        if (this.selectSites.length === this.siteRow.sites.length) {
            this.selectAll = true
        }
        if (this.mobile) {
            this.maxRows = 16
        } else {
            this.maxRows = 4
        }
    }
}
</script>

<style>
.site-flex {
    display: flex;
    align-items: baseline;
    justify-content: center;
}
.expand-div {
    margin-top: 12px;
}
.edit-button {
    margin: 0 0 18px 0;
}
.site-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
}
.site-bg:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  padding-bottom: calc(9/16 * 100%);
}
</style>
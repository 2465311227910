<template>
    <div class="layout-padding">
        <div v-if="null === selectedSite">
            <div v-for="(row,index) in layout" :key="index">
                <div v-if="'broadcast' === row.type">
                    <BroadcastsRow type="layout" :layoutIndex="index" :queries="row.broadcastRow" :start="0" :end="1" v-show="viewBroadcasts" />
                </div>
                <div v-else-if="'site' === row.type">
                    <SitesRow :search="false" :row="row.siteRow" v-show="viewSites" />
                </div>
                <div v-else-if="'embeddedApp' === row.type">
                    <AppsRow :search="false" :row="row.appRow" v-show="viewApps" />
                </div>
                <div v-else-if="'videoSourceSchedule' === row.type">
                    <SchedulesRow :row="row.scheduleRow" v-show="viewSchedules" />
                </div>
                <div v-else-if="'news' === row.type">
                    <NewsRow :row="row.newsRow" v-show="viewNews" />
                </div>
                <div v-else-if="'sponsors' === row.type">
                    <SponsorsRow :row="row.sponsorsRow" />
                </div>
            </div>
        </div>
        <div v-else>
            <BroadcastsRow type="site" :site="selectedSite" :queries="sites[selectedSite].queries" :start="0" :end="sites[selectedSite].queries.length" v-show="viewBroadcasts" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import BroadcastsRow from './BroadcastsRow.vue'
import SitesRow from './SitesRow.vue'
import AppsRow from './AppsRow.vue'
import SchedulesRow from './SchedulesRow.vue'
import NewsRow from './NewsRow.vue'
import SponsorsRow from './SponsorsRow.vue'

export default {
    name: 'LayoutRow',
    components: {
        BroadcastsRow,
        SitesRow,
        AppsRow,
        SchedulesRow,
        NewsRow,
        SponsorsRow
    },
    computed: {
        ...mapState([
            'layout',
            'selectedSite',
            'sites',
            'viewApps',
            'viewBroadcasts',
            'viewNews',
            'viewSchedules',
            'viewSites'
        ])
    },
}
</script>

<style>
.layout-padding {
    padding: 6px 0 12px 0;
}
</style>
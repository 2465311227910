<template>
    <v-combobox color="secondary lighten-3" v-model="selected" :items="sectionTitles" :label="'Search by ' + labels.sectionLabel.toLowerCase()" clearable></v-combobox>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'SearchSection',
    props: {
        searchSectionTitle: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            selected: ''
        }
    },
    computed: {
        sectionTitles() {
            let sectionTitles = []
            for (let i = 0; i < this.sectionArray.length; ++i) {
                sectionTitles.push(this.sectionArray[i].title)
            }
            return sectionTitles
        },
        ...mapState([
            'labels',
            'sectionArray'
        ])
    },
    watch: {
        selected() {
            if (typeof(this.selected) === 'undefined') {
                this.$emit('search-section', null)
            } else {
                for (let i = 0; i < this.sectionArray.length; ++i) {
                    if (this.selected === this.sectionArray[i].title) {
                        this.$emit('search-section', i)
                        break
                    }
                }
            }
        },
        searchSectionTitle() {
            if (this.selected !== this.searchSectionTitle) {
                this.selected = this.searchSectionTitle
            }
        }
    }
}
</script>

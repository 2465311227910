import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: window.location.pathname,
    routes: [
        {
            path: '*'
        }
    ]
})

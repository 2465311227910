<template>
    <div id="stats_parent">
        <iframe id="live_stats_embed" src="about:blank" scrolling="yes" frameborder="0"
            :style="statsStyle" :title="'Live Stats for: '+title" aria-live="polite" :aria-label="'Live Stats for: '+title">
        </iframe>
    </div>
</template>

<script>
export default {
    name: 'PlayerStats',
    props: {
        embed: {
            type: String,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },
    computed: {
        statsStyle() {
            return {
                'height': this.height+'px',
                'width': '100%'
            }
        }
    },
    methods: {
        loadIframe() {
            var iframe = document.getElementById('live_stats_embed')
            if (iframe) {
                let container = iframe.parentElement
                iframe.remove()
                iframe.setAttribute('src', this.embed)
                container.append(iframe)
            }
        },
    },
    watch: {
        embed() {
            this.loadIframe()
        }
    },
    mounted() {
        this.loadIframe()
    }
}
</script>

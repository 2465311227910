<template>
    <div v-if="showSearch" title="Search" aria-label="Search" :class="searchWrapperClass" :style="searchStyle">
        <div :class="searchPanelClass">
            <v-layout>
                <v-icon>fo-search</v-icon>
                <v-layout column search-padding>
                    <v-text-field class="omni-item-large" color="secondary lighten-3" v-model="searchString" label="Search" append-outer-icon="fo-settings" @click:append-outer="toggleAdvanced" clearable></v-text-field>
                    <small class="omni-item-large" v-html="error"></small>
                    <v-expand-transition>
                        <div class="omni-items" v-show="advancedSearch">
                            <div class="omni-item-medium" v-show="filters.showSectionsFilter && (sectionsString.length > 0)">
                                <SearchSection :searchSectionTitle="searchSectionTitle" @search-section="sectionSearch" />
                            </div>
                            <div class="omni-item-medium" v-show="filters.showDateFilter">
                                <SearchDate :searchDates="searchDates" @search-dates="dateSearch" />
                            </div>
                            <div class="omni-item-medium" v-show="filters.showSitesFilter && (null === selectedSite)">
                                <SearchSite :searchSiteTitle="searchSiteTitle" @search-site="siteSearch" />
                            </div>
                        </div>
                    </v-expand-transition>
                </v-layout>
            </v-layout>
            <v-expand-transition>
                <div class="search-results-padding" v-show="search">
                    <div v-if="searchReady">
                        <BroadcastsRow type="search" :queries="searchQueries" :start="searchIndex" :end="searchIndexEnd" />
                    </div>
                    <div v-else class="spinner-search">
                        <v-progress-circular color="secondary lighten-3" :size="40" :width="3" indeterminate></v-progress-circular>
                    </div>
                    <div v-if="null === selectedSite">
                        <div v-for="(row,index) in layout" :key="index">
                            <div v-if="'site' === row.type">
                                <SitesRow :search="true" :row="row.siteRow" />
                            </div>
                            <div v-else-if="'embeddedApp' === row.type">
                                <AppsRow :search="true" :row="row.appRow" />
                            </div>
                        </div>
                    </div>
                    <v-layout justify-center>
                        <v-btn small flat :dark="dark" @click="resetSearch" aria-label="Clear All">
                            Clear all
                        </v-btn>
                    </v-layout>
                </div>
            </v-expand-transition>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import debounce from 'tiny-debounce'
import SearchSite from './Search/SearchSite.vue'
import SearchSection from './Search/SearchSection.vue'
import SearchDate from './Search/SearchDate.vue'
import BroadcastsRow from './BroadcastsRow.vue'
import SitesRow from './SitesRow.vue'
import AppsRow from './AppsRow.vue'

const defaultSearch = () => {
    return {
        advancedSearch: false,
        error: '',
        searchByDate: false,
        searchBySite: false,
        searchBySection: false,
        searchByTitle: false,
        searchDates: null,
        searchQuery: '',
        searchSiteId: null,
        searchSiteTitle: '',
        searchSectionId: null,
        searchSectionTitle: '',
        searchString: ''
    }
}

export default {
    name: 'OmniSearch',
    components: {
        SearchSite,
        SearchSection,
        SearchDate,
        BroadcastsRow,
        SitesRow,
        AppsRow
    },
    data() {
        return defaultSearch()
    },
    computed: {
        searchIndexEnd() {
            if (!this.searchBySite && !this.searchBySection && !this.searchByDate) {
                return this.searchIndex + 2
            } else {
                return this.searchIndex + 1
            }
        },
        searchPanelClass() {
            let searchClass = 'omni-search-bar elevation-1'
            if (this.fullSearch) {
                searchClass += ' primary-bg inner-div no-glow'
            }
            return searchClass
        },
        searchStyle() {
            if (this.fullSearch) {
                return {
                    'width': '100vw',
                    'position': 'relative',
                    'left': '50%',
                    'right': '50%',
                    'margin-left': '-50vw',
                    'margin-right': '-50vw',
                    'margin-bottom': '24px'
                }
            } else {
                return {
                    'margin-top': '24px'
                }
            }
        },
        searchWrapperClass() {
            if (this.fullSearch || this.dark) {
                return 'primary-bg'
            } else {
                return ''
            }
        },
        ...mapState([
            'dark',
            'filters',
            'fullSearch',
            'layout',
            'omnisearch',
            'search',
            'searchIndex',
            'searchQueries',
            'searchReady',
            'sectionArray',
            'sectionsString',
            'selectedSite',
            'showSearch',
            'siteArray',
            'sitesString'
        ])
    },
    methods: {
        resetSearch() {
            this.$store.commit('SET_SEARCH', false)
            this.$store.commit('SET_SEARCHREADY',false)
            this.$store.commit('SET_SEARCHINDEX', null)
            this.$store.commit('SET_OMNISEARCH', '')
        },
        buildSearch(type) {
            let newQuery = {}
            newQuery.searchQuery = this.searchQuery
            newQuery.response = { broadcasts: [] }
            newQuery.updates = 0
            newQuery.broadcastSearchParams = {}
            newQuery.broadcastSearchParams.include_deletions = 0
            newQuery.broadcastSearchParams.page = 1
            newQuery.broadcastSearchParams.per_page = 25
            // site_id always required
            if (null !== this.selectedSite) {
                newQuery.broadcastSearchParams.site_id = this.selectedSite.toString()
            } else if (this.searchBySite) {
                newQuery.broadcastSearchParams.site_id = this.searchSiteId
            } else {
                newQuery.broadcastSearchParams.site_id = this.sitesString
            }
            // Section_id required to omit test/invalid sections
            if (this.searchBySection) {
                newQuery.broadcastSearchParams.section_id = this.searchSectionId
            } else if (this.sectionsString.length > 0) {
                newQuery.broadcastSearchParams.section_id = this.sectionsString
            }
            if (this.searchByTitle) {
                newQuery.broadcastSearchParams.title = this.searchString
            }
            // Date not required
            if (this.searchByDate) {
                let startDate = new Date(this.searchDates.start.getTime())
                let endDate = new Date(this.searchDates.end.getTime())
                if (startDate > endDate) {
                    let tempDate = new Date(endDate.getTime())
                    endDate = new Date(startDate.getTime())
                    startDate = new Date(tempDate.getTime())
                }
                startDate.setHours(0,0,0,0)
                endDate.setHours(0,0,0,0)
                endDate.setDate(endDate.getDate() + 1)
                newQuery.broadcastSearchParams.after = startDate.toUTCString()
                newQuery.broadcastSearchParams.before = endDate.toUTCString()
            }
            newQuery.broadcastSearchParams.sort_by = 'date'

            let index = this.searchIndex
            if (type === 'live') {
                newQuery.title = 'Live / Upcoming'
                newQuery.broadcastSearchParams.sort_dir = 'asc'
                newQuery.broadcastSearchParams.viewer_status = 3
            } else if (type === 'recent') {
                ++index
                newQuery.title = 'Recent'
                newQuery.broadcastSearchParams.sort_dir = 'desc'
                newQuery.broadcastSearchParams.viewer_status = 4
            } else if (type === 'advanced') {
                newQuery.title = this.searchQuery
            }
            this.$store.commit('ADD_SEARCHQUERY', newQuery)
            this.$store.dispatch('GET_BROADCASTS', {type:'search', index:index, update:false})
        },
        checkSearch() {
            this.$store.commit('SET_SEARCHREADY',false)
            if (!this.searchBySite && !this.searchBySection && !this.searchByTitle && !this.searchByDate) {
                this.$store.commit('SET_SEARCH', false)
            } else {
                this.$store.commit('SET_SEARCH', true)
                let advanced = false
                this.searchQuery = 'Broadcasts'
                if (this.searchBySite) {
                    this.searchQuery += ' by ' + this.searchSiteTitle
                    advanced = true
                }
                if (this.searchBySection) {
                    this.searchQuery += ' featuring ' + this.searchSectionTitle
                    advanced = true
                }
                if (this.searchByTitle) {
                    this.searchQuery += ' named ' + this.searchString
                }
                if (this.searchByDate) {
                    let startDate = new Date(this.searchDates.start.getTime())
                    let endDate = new Date(this.searchDates.end.getTime())
                    if (startDate > endDate) {
                        let tempDate = new Date(endDate.getTime())
                        endDate = new Date(startDate.getTime())
                        startDate = new Date(tempDate.getTime())
                    }
                    let start = ( startDate.getMonth() + 1 ) + '/' + startDate.getDate() + '/' + startDate.getFullYear()
                    let end = ( endDate.getMonth() + 1 ) + '/' + endDate.getDate() + '/' + endDate.getFullYear()
                    this.searchQuery += ' between ' + start + ' and ' + end
                    advanced = true
                }
                let newSearch = true
                for (let i = 0; i < this.searchQueries.length; ++i) {
                    if (this.searchQuery === this.searchQueries[i].searchQuery) {
                        newSearch = false
                        this.$store.commit('SET_SEARCHINDEX',i)
                        this.$store.commit('SET_SEARCHREADY',true)
                        break
                    }
                }
                if (newSearch) {
                    this.$store.commit('SET_SEARCHINDEX',this.searchQueries.length)
                    if (advanced) {
                        this.buildSearch('advanced')
                    } else {
                        this.buildSearch('live')
                        this.buildSearch('recent')
                    }
                }
            }
        },
        dateSearch(dates) {
            this.searchDates = dates
            if (null === this.searchDates) {
                this.searchByDate = false
            } else {
                this.searchByDate = true
            }
            this.checkSearch()
        },
        sectionSearch(index) {
            if (null === index) {
                this.searchBySection = false
                this.searchSectionId = null
                this.searchSectionTitle = ''
            } else {
                this.searchBySection = true
                this.searchSectionId = this.sectionArray[index].id.toString()
                this.searchSectionTitle = this.sectionArray[index].title
            }
            this.checkSearch()
        },
        siteSearch(index) {
            if (null === index) {
                this.searchBySite = false
                this.searchSiteId = null
                this.searchSiteTitle = ''
            } else {
                this.searchBySite = true
                this.searchSiteId = this.siteArray[index].id.toString()
                this.searchSiteTitle = this.siteArray[index].title
                this.$store.commit('SET_OMNISEARCH', this.searchSiteTitle)
            }
            this.checkSearch()
        },
        toggleAdvanced() {
            this.advancedSearch = !this.advancedSearch
        }
    },
    watch: {
        search() {
            if (!this.search) {
                Object.assign(this.$data, defaultSearch())
            }
        },
        searchString: debounce(function (newVal) {
            this.error = ''
            if (this.searchString !== this.omnisearch) {
                if (null !== newVal && '' !== newVal) {
                    if (newVal.length > 1) {
                        this.$store.commit('SET_OMNISEARCH', newVal)
                        this.searchByTitle = true
                        this.checkSearch()
                    } else {
                        this.error = 'Please enter at least 2 characters'
                    }
                } else {
                    this.$store.commit('SET_OMNISEARCH', '')
                    this.searchByTitle = false
                    this.checkSearch()
                }
            }
        }, 500)
    }
}
</script>

<style>
.omni-search-bar {
    padding:12px 24px;
}
.omni-search-bar:hover {
    box-shadow: 0 0 4px 3px var(--v-primary-lighten4) !important;
}
.omni-search-bar:focus {
    box-shadow: 0 0 4px 3px var(--v-primary-lighten4) !important;
}
.omni-search-bar:active {
    box-shadow: 0 0 4px 3px var(--v-primary-lighten4) !important;
}
.search-padding {
    padding:0 0 0 12px;
}
.search-results-padding {
    padding:18px 0 0 0;
}
.omni-items {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}
.omni-item-small {
    flex-grow: 0;
    margin: 0 6px;
}
.omni-item-medium {
    flex-grow: 1;
    margin: 0 6px;
}
.omni-item-large {
    flex-grow: 2;
    margin: 0 6px;
}
.spinner-search {
    margin: 140px 0;
}
</style>
<template>
    <div class="btmnav">
        <v-bottom-nav :class="navClass" mandatory v-if="showBottomNav" style="transform:initial;box-shadow:rgba(0, 0, 0, 0.5) 0px 1px 9px 2px;">
            <v-btn v-if="hasBroadcasts" @click="selectView('Broadcasts')" @keyup.enter.space="selectView('Broadcasts')" tabindex="0" role="button" aria-pressed="false" aria-label="Broadcasts View">
                <span>{{labels.broadcastLabelPlural}}</span>
                <v-icon>fo-broadcasts</v-icon>
            </v-btn>

            <v-btn v-if="hasSites" @click="selectView('Sites')" @keyup.enter.space="selectView('Sites')" tabindex="0" role="button" aria-pressed="false" aria-label="Sites View">
                <span>{{labels.siteLabelPlural}}</span>
                <v-icon>fo-sites</v-icon>
            </v-btn>

            <v-btn v-if="hasApps" @click="selectView('Apps')" @keyup.enter.space="selectView('Apps')" tabindex="0" role="button" aria-pressed="false" aria-label="Apps View">
                <span>{{labels.appLabelPlural}}</span>
                <v-icon>fo-apps</v-icon>
            </v-btn>

            <v-btn v-if="hasSchedules" @click="selectView('Schedules')" @keyup.enter.space="selectView('Schedules')" tabindex="0" role="button" aria-pressed="false" aria-label="Schedules View">
                <span>VSS</span>
                <v-icon>fo-sites</v-icon>
            </v-btn>

            <v-btn v-if="hasNews" @click="selectView('News')" @keyup.enter.space="selectView('News')" tabindex="0" role="button" aria-pressed="false" aria-label="News View">
                <span>News</span>
                <v-icon>fo-news</v-icon>
            </v-btn>
        </v-bottom-nav>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'BottomNavRow',
    computed: {
        navClass() {
            if (this.dark) {
                return 'primary-bg'
            } else {
                return ''
            }
        },
        showBottomNav() {
            if (this.selectedSite === null && this.viewCount > 1) {
                return true
            } else {
                return false
            }
        },
        viewCount() {
            let view_count = 0
            if (this.hasBroadcasts) {
                ++view_count
            }
            if (this.hasSites) {
                ++view_count
            }
            if (this.hasApps) {
                ++view_count
            }
            if (this.hasSchedules) {
                ++view_count
            }
            if (this.hasNews) {
                ++view_count
            }
            return view_count
        },
        ...mapState([
            'dark',
            'firstLoad',
            'hasApps',
            'hasBroadcasts',
            'hasNews',
            'hasSchedules',
            'hasSites',
            'labels',
            'position',
            'selectedSite',
            'width'
        ]),
        ...mapGetters([
            'mobile'
        ])
    },
    methods: {
        selectView(option = null) {
            if (null !== option) {
                switch(option)
                {
                    case 'Broadcasts':
                        this.viewBroadcasts()
                        break;
                    case 'Sites':
                        this.viewSites()
                        break;
                    case 'Apps':
                        this.viewApps()
                        break;
                    case 'Schedules':
                        this.viewSchedules()
                        break;
                    case 'News':
                        this.viewNews()
                        break;
                }
                window.scroll({ top: this.position.y, left: this.position.x, behavior: 'smooth' })
            } else if (this.mobile) {
                if (this.hasBroadcasts) {
                    this.viewBroadcasts()
                } else if (this.hasSites) {
                    this.viewSites()
                } else if (this.hasApps) {
                    this.viewApps()
                } else if (this.hasSchedules) {
                    this.viewSchedules()
                } else if (this.hasNews) {
                    this.viewNews()
                } else {
                    this.viewAll()
                }
            } else {
                this.viewAll()
            }
            let footerLinks = document.getElementsByClassName('footer-links')
            if (footerLinks.length > 0) {
                let footerClasses = footerLinks[0].classList
                if (this.mobile) {
                    if (this.showBottomNav && !footerClasses.contains('bf-logo-padding')) {
                        footerClasses.add('bf-logo-padding')
                    }
                } else {
                    if (footerClasses.contains('bf-logo-padding')) {
                        footerClasses.remove('bf-logo-padding')
                    }
                }
            }
        },
        viewAll() {
            this.$store.commit('SET_VIEWS', { player: true, broadcasts: true, sites: true, apps: true, schedules: true, news: true })
        },
        viewApps() {
            this.$store.commit('SET_VIEWS', { player: false, broadcasts: false, sites: false, apps: true, schedules: false, news: false })
        },
        viewBroadcasts() {
            this.$store.commit('SET_VIEWS', { player: true, broadcasts: true, sites: false, apps: false, schedules: false, news: false })
        },
        viewNews() {
            this.$store.commit('SET_VIEWS', { player: false, broadcasts: false, sites: false, apps: false, schedules: false, news: true })
        },
        viewSchedules() {
            this.$store.commit('SET_VIEWS', { player: false, broadcasts: false, sites: false, apps: false, schedules: true, news: false })
        },
        viewSites() {
            this.$store.commit('SET_VIEWS', { player: false, broadcasts: false, sites: true, apps: false, schedules: false, news: false })
        }
    },
    watch: {
        firstLoad() {
            this.selectView()
        },
        width() {
            this.selectView()
        }
    }
}
</script>

<style>
.btmnav {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10;
}
.bf-logo-padding {
    padding-bottom: 65px;
}
</style>
import './assets/css/fontello.css'
import './assets/css/vuetify.css'

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'

const FONTELLO = {
    'complete': 'fo-check',
    'cancel': 'fo-cancel',
    'close': 'fo-close',
    'delete': 'fo-cancel',
    'clear': 'fo-close',
    'success': 'fo-check-circle',
    'info': 'fo-info',
    'warning': 'fo-important',
    'error': 'fo-alert',
    'prev': 'fo-left',
    'next': 'fo-right',
    'checkboxOn': 'fo-checked',
    'checkboxOff': 'fo-unchecked',
    'checkboxIndeterminate': 'fo-unsure',
    'delimiter': 'fo-circle',
    'sort': 'fo-arrow-up',
    'expand': 'fo-down',
    'menu': 'fo-menu',
    'subgroup': 'fo-menu-down',
    'dropdown': 'fo-menu-down',
    'radioOn': 'fo-radio-marked',
    'radioOff': 'fo-radio-blank',
    'edit': 'fo-pencil',
    'ratingEmpty': 'fo-star-blank',
    'ratingFull': 'fo-star',
    'ratingHalf': 'fo-star-half',
    'loading': 'fo-cached',
}

Vue.use(Vuetify, {
    icons: FONTELLO,
    options: {
        customProperties: true
    },
    theme: false
})

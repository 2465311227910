<template>
    <v-text-field color="secondary lighten-3" v-model="title" :label="'Search by '+labels.broadcastLabel.toLowerCase()"></v-text-field>
</template>

<script>
import { mapState } from 'vuex'
import debounce from 'tiny-debounce'

export default {
    name: 'SearchTitle',
    props: {
        searchTitleName: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapState([
            'labels'
        ])
    },
    data() {
        return {
            title: this.searchTitleName,
            selected: ''
        }
    },
    watch: {
        selected() {
            this.$emit('search-title', this.selected)
        },
        searchTitleName() {
            this.title = this.searchTitleName
        },
        title: debounce(function (newVal) {
            if (this.title !== this.searchTitleName) {
                this.selected = newVal
            }
        }, 500)
    }
}
</script>

<template>
    <div id="player_parent">
        <iframe id="player_video" src="about:blank" :allow="'fullscreen; geolocation \'self\' https://'+ this.config.vCloud.domain +';'"
            :style="videoStyle" :title="'Video: '+title" aria-live="polite" :aria-label="'Video: '+title">
        </iframe>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'PlayerVideo',
    props: {
        embed: {
            type: String,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },
    computed: {
        videoStyle() {
            return {
                'height': this.height+'px',
                'width': '100%',
                'border': '0',
                'margin': '0',
                'padding': '0',
                'overflow': 'hidden'
            }
        },
        ...mapState([
            'config'
        ])
    },
    methods: {
        loadIframe() {
            var iframe = document.getElementById('player_video')
            if (iframe) {
                let container = iframe.parentElement
                iframe.remove()
                iframe.setAttribute('src', this.embed)
                container.append(iframe)
            }
        },
    },
    watch: {
        embed() {
            this.loadIframe()
        }
    },
    mounted() {
        this.loadIframe()
    }
}
</script>

<template>
    <div>
        <div v-for="(row,index) in queries" :key="index">
            <div v-if="index >= start && index < end">
                <div v-if="'search' !== type && 0 === row.response.broadcasts.length">
                    <!-- Show Nothing -->
                </div>
                <div v-else class="layout-row">
                    <div class="mobile-padding row-title" role="heading" aria-level="2">{{ row.title }}</div>
                    <div v-if="0 === row.response.broadcasts.length" :style="empty">
                        <p>No broadcasts found.</p>
                    </div>
                    <div v-else>
                        <SwiperControl :layoutIndex="layoutIndex? layoutIndex : index" :row="row" :site="site" :type="type" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import SwiperControl from './Swiper/SwiperControl.vue'

export default {
    name: 'BroadcastsRow',
    components: {
        SwiperControl
    },
    props: {
        type: {
            type: String,
            required: true
        },
        site: {
            type: Number,
            required: false,
            default: null
        },
        layoutIndex: {
            type: Number,
            required: false,
            default: null
        },
        queries: {
            type: Array,
            required: true
        },
        start: {
            type: Number,
            required: true
        },
        end: {
            type: Number,
            required: true
        }
    },
    computed: {
        empty() {
            if (this.searchOmni && 'search' == this.type) {
                return {
                    'height': '40px'
                }
            } else {
                return {
                    'display': 'flex',
                    'flex-direction': 'column',
                    'justify-content': 'center',
                    'align-items': 'center',
                    'height': '279px'
                }
            }
        },
        ...mapState([
            'searchOmni'
        ])
    }
}
</script>
